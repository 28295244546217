import React from "react";
import Searchbar from "./TopbarComponents/Searchbar";
import Changemode from "./TopbarComponents/Changemode";
import SearchbarContent from "./TopbarComponents/SearchbarContent";
import Helper from "./TopbarComponents/Helper";
import Setting from "./TopbarComponents/Setting";
import Profile from "./TopbarComponents/Profile";

function Topbar() {
  return (
    <>
      <div className="iq-top-navbar pt-0">
        <div className="iq-navbar-custom">
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <div className="iq-navbar-logo d-flex align-items-center justify-content-between">
              <i className="ri-menu-line wrapper-menu" />
            </div>
            <Searchbar />

            <div className="d-flex align-items-center">
              <Changemode />

              <div>
                <ul className="navbar-nav ml-auto navbar-list align-items-center">
                  <SearchbarContent />

                  <Helper />

                  <Setting />

                  <Profile />
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Topbar;
