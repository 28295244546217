import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import * as XLSX from "xlsx"; // Import library xlsx
import jsPDF from "jspdf"; // Import library jsPDF
import "jspdf-autotable"; // Import plugin autotable untuk tabel PDF

// Komponen untuk menampilkan jadwal tiap kelas
function JadwalItem({
  keterangan,
  kodeGuru,
  onChangeGuru,
  id,
  tenagaPendidik,
  field
}) {
  const [selectedKodeGuru, setSelectedKodeGuru] = useState(kodeGuru);

  useEffect(() => {
    // Ketika kodeGuru berubah, perbarui kode guru yang dipilih
    setSelectedKodeGuru(kodeGuru);
  }, [kodeGuru]);

  // Menemukan mata pelajaran guru berdasarkan kode pegawai
  const getMatpelGuru = (kodeGuru) => {
    const guru = tenagaPendidik.find((guru) => guru.kode_pegawai === kodeGuru);
    return guru ? guru.mata_pelajaran : "-";
  };

  return (
    <td>
      {keterangan ? (
        <span className="text-primary font-weight-bold">{keterangan}</span>
      ) : (
        <span>
          {/* Select form untuk memilih guru */}
          <select
            className="form-select"
            value={selectedKodeGuru}
            onChange={(e) => {
              const newKodeGuru = e.target.value;
              setSelectedKodeGuru(newKodeGuru); // Update local state
              onChangeGuru(id, field, newKodeGuru); // Update jadwal
            }}
            aria-label="Pilih Guru"
          >
            <option value="">Pilih Guru</option>
            {tenagaPendidik.map((guru) => (
              <option key={guru.kode_pegawai} value={guru.kode_pegawai}>
                {guru.nama}
              </option>
            ))}
          </select>
          {/* Tampilkan nama guru di bawah select */}
          <span className="mt-1 d-block">
            Pelajaran {getMatpelGuru(selectedKodeGuru)}
          </span>
        </span>
      )}
    </td>
  );
}

function JadwalPelajaran() {
  const [jadwal, setJadwal] = useState([]);
  const [tenagaPendidik, setTenagaPendidik] = useState([]);
  const [hari, setHari] = useState("SENIN");

  // Mendapatkan data jadwal dan tenaga pendidik saat komponen dimount
  useEffect(() => {
    getJadwal();
    getTenagaPendidik();
  }, []);

  // Memuat ulang jadwal berdasarkan hari yang dipilih
  useEffect(() => {
    getJadwal(); // Memuat jadwal untuk hari yang baru dipilih
  }, [hari]);

  // Fungsi untuk mendapatkan jadwal
  const getJadwal = async () => {
    try {
      const response = await axios.get("/administrator/jadwal-pelajaran");
      // Asumsikan response.data adalah array flat dari item jadwal
      setJadwal(response.data);
    } catch (error) {
      console.error("Error fetching jadwal:", error);
    }
  };

  // Fungsi untuk mendapatkan data tenaga pendidik
  const getTenagaPendidik = async () => {
    try {
      const response = await axios.get("/administrator/tenaga-pendidik");
      setTenagaPendidik(response.data);
    } catch (error) {
      console.error("Error fetching tenaga pendidik:", error);
    }
  };

  // Fungsi untuk ekspor ke Excel
  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(jadwalFiltered); // Konversi jadwal menjadi sheet Excel
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Jadwal Pelajaran");

    XLSX.writeFile(workbook, `jadwal_pelajaran_${hari}.xlsx`); // Nama file yang dihasilkan
  };

  // Fungsi untuk ekspor ke PDF
  const handleExportPDF = () => {
    const doc = new jsPDF();
    const tableColumn = [
      "Waktu",
      "Kelas X-1",
      "Kelas X-2",
      "Kelas X-3",
      "Kelas X-4",
      "XI MIPA",
      "XI IPS-1",
      "XI IPS-2",
      "XII MIPA",
      "XII IPS-1",
      "XII IPS-2"
    ];
    const tableRows = jadwalFiltered.map((item) => [
      item.waktu,
      item.x_1,
      item.x_2,
      item.x_3,
      item.x_4,
      item.xi_mipa,
      item.xi_ips_1,
      item.xi_ips_2,
      item.xii_mipa,
      item.xii_ips_1,
      item.xii_ips_2
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows
    });

    doc.save(`jadwal_pelajaran_${hari}.pdf`); // Nama file PDF yang dihasilkan
  };

  // Menangani perubahan kode guru
  const handleChangeGuru = (id, field, kodeGuruBaru) => {
    setJadwal((prevJadwal) =>
      prevJadwal.map((item) => {
        if (item.id === id) {
          const updatedItem = { ...item, [field]: kodeGuruBaru };
          return updatedItem;
        }
        return item;
      })
    );
  };

  // Menyimpan semua perubahan jadwal
  const handleSaveAll = async () => {
    const payload = {
      hari,
      jadwal: jadwal.filter((item) => item.hari === hari) // Hanya kirim jadwal untuk hari yang dipilih
    };

    try {
      await axios.put("/administrator/jadwal-pelajaran/update-all", payload);
      Swal.fire({
        title: "Sukses!",
        text: "Jadwal pelajaran berhasil disimpan!",
        icon: "success",
        confirmButtonText: "OK"
      });
    } catch (error) {
      Swal.fire({
        title: "Gagal!",
        text: "Terjadi kesalahan saat menyimpan jadwal.",
        icon: "error",
        confirmButtonText: "OK"
      });
    }
  };

  // Filter jadwal berdasarkan hari yang dipilih
  const jadwalFiltered = jadwal.filter((item) => item.hari === hari);

  return (
    <div
      className="tab-pane fade"
      id="jadwal-justify"
      role="tabpanel"
      aria-labelledby="jadwal-tab-justify"
    >
      <div className="alert alert-primary mt-3" role="alert">
        <div className="iq-alert-text">
          Daftar Jadwal Pelajaran <b>SMA Muhammadiyah Tasikmalaya</b>
          <br />
          <small className="text-danger font-weight-bold">
            *Silahkan memilih hari dan isi jadwal dari table dibawah.
          </small>
        </div>
      </div>

      {/* Dropdown untuk memilih hari */}
      <div className="mb-3">
        <label>Jadwal Hari : </label>{" "}
        <select
          className="form-select"
          value={hari}
          onChange={(e) => setHari(e.target.value)}
        >
          <option value="SENIN">SENIN</option>
          <option value="SELASA">SELASA</option>
          <option value="RABU">RABU</option>
          <option value="KAMIS">KAMIS</option>
          <option value="JUMAT">JUMAT</option>
        </select>
      </div>

      {/* Tombol Simpan dan Ekspor */}
      <button className="btn btn-primary mt-3 mr-2" onClick={handleSaveAll}>
        Simpan Jadwal
      </button>
      <button className="btn btn-success mt-3 mr-2" onClick={handleExportExcel}>
        Export ke Excel
      </button>
      <button className="btn btn-danger mt-3 mr-2" onClick={handleExportPDF}>
        Export ke PDF
      </button>

      {/* Membuat tabel responsif */}
      <div className="table-responsive">
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>Waktu</th>
              <th>Kelas X-1</th>
              <th>Kelas X-2</th>
              <th>Kelas X-3</th>
              <th>Kelas X-4</th>
              <th>Kelas XI MIPA</th>
              <th>Kelas XI IPS-1</th>
              <th>Kelas XI IPS-2</th>
              <th>Kelas XII MIPA</th>
              <th>Kelas XII IPS-1</th>
              <th>Kelas XII IPS-2</th>
            </tr>
          </thead>
          <tbody>
            {jadwalFiltered.map((item) => (
              <tr key={item.id}>
                <td>{item.waktu}</td>
                <JadwalItem
                  keterangan={item.keterangan}
                  kodeGuru={item.x_1}
                  onChangeGuru={handleChangeGuru}
                  id={item.id}
                  tenagaPendidik={tenagaPendidik}
                  field="x_1"
                />
                <JadwalItem
                  keterangan={item.keterangan}
                  kodeGuru={item.x_2}
                  onChangeGuru={handleChangeGuru}
                  id={item.id}
                  tenagaPendidik={tenagaPendidik}
                  field="x_2"
                />
                <JadwalItem
                  keterangan={item.keterangan}
                  kodeGuru={item.x_3}
                  onChangeGuru={handleChangeGuru}
                  id={item.id}
                  tenagaPendidik={tenagaPendidik}
                  field="x_3"
                />
                <JadwalItem
                  keterangan={item.keterangan}
                  kodeGuru={item.x_4}
                  onChangeGuru={handleChangeGuru}
                  id={item.id}
                  tenagaPendidik={tenagaPendidik}
                  field="x_4"
                />
                <JadwalItem
                  keterangan={item.keterangan}
                  kodeGuru={item.xi_mipa}
                  onChangeGuru={handleChangeGuru}
                  id={item.id}
                  tenagaPendidik={tenagaPendidik}
                  field="xi_mipa"
                />
                <JadwalItem
                  keterangan={item.keterangan}
                  kodeGuru={item.xi_ips_1}
                  onChangeGuru={handleChangeGuru}
                  id={item.id}
                  tenagaPendidik={tenagaPendidik}
                  field="xi_ips_1"
                />
                <JadwalItem
                  keterangan={item.keterangan}
                  kodeGuru={item.xi_ips_2}
                  onChangeGuru={handleChangeGuru}
                  id={item.id}
                  tenagaPendidik={tenagaPendidik}
                  field="xi_ips_2"
                />
                <JadwalItem
                  keterangan={item.keterangan}
                  kodeGuru={item.xii_mipa}
                  onChangeGuru={handleChangeGuru}
                  id={item.id}
                  tenagaPendidik={tenagaPendidik}
                  field="xii_mipa"
                />
                <JadwalItem
                  keterangan={item.keterangan}
                  kodeGuru={item.xii_ips_1}
                  onChangeGuru={handleChangeGuru}
                  id={item.id}
                  tenagaPendidik={tenagaPendidik}
                  field="xii_ips_1"
                />
                <JadwalItem
                  keterangan={item.keterangan}
                  kodeGuru={item.xii_ips_2}
                  onChangeGuru={handleChangeGuru}
                  id={item.id}
                  tenagaPendidik={tenagaPendidik}
                  field="xii_ips_2"
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default JadwalPelajaran;
