import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app"; // Mengimport komponen utama App

// Menggunakan createRoot dari 'react-dom/client' untuk membuat root
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render komponen App
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
