import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function WebsiteBerita() {
  const [beritaList, setBeritaList] = useState([]);
  const [selectedBerita, setSelectedBerita] = useState(null);

  // Fungsi untuk mengambil daftar berita
  const fetchBerita = async () => {
    try {
      const response = await axios.get("/administrator/berita");
      setBeritaList(response.data);
    } catch (error) {
      console.error("Error fetching berita:", error);
    }
  };

  // Mengambil data berita saat komponen dimuat
  useEffect(() => {
    fetchBerita();
  }, []);

  // Fungsi untuk menghapus berita
  const deleteBerita = async (id) => {
    const confirmDelete = await Swal.fire({
      title: "Yakin ingin menghapus?",
      text: "Anda tidak akan bisa mengembalikan data ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal"
    });

    if (confirmDelete.isConfirmed) {
      try {
        await axios.delete(`/administrator/berita/${id}`);
        Swal.fire("Terhapus!", "Data berita telah dihapus.", "success");
        fetchBerita(); // Refresh data
      } catch (error) {
        console.error("Error deleting berita:", error);
        Swal.fire("Error", "Gagal menghapus data.", "error");
      }
    }
  };

  // Fungsi untuk menambahkan berita baru dengan SweetAlert
  const handleTambahBerita = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Tambah Berita Baru",
      html: `
        <label for="swal-input0">Foto</label>
        <input id="swal-input0" class="swal2-input" type="file">
        <label for="swal-input1">Judul Berita</label>
        <input id="swal-input1" class="swal2-input" placeholder="Masukkan Judul Berita">
        <label for="swal-input2">Keterangan Berita</label>
        <textarea id="swal-input2" class="swal2-textarea" placeholder="Keterangan Berita"></textarea>
        <label for="swal-input3">Link Berita</label>
        <input id="swal-input3" class="swal2-input" placeholder="Link Berita">`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        const judul = document.getElementById("swal-input1").value;
        const keterangan = document.getElementById("swal-input2").value;
        const link = document.getElementById("swal-input3").value;
        const foto = document.getElementById("swal-input0").files[0]; // Pastikan mengambil file dengan benar

        if (!judul || !keterangan || !link || !foto) {
          Swal.showValidationMessage("Semua kolom harus diisi!");
          return false;
        }

        // Membuat FormData untuk mengirimkan file foto sebagai multipart
        const formData = new FormData();
        formData.append("foto", foto);
        formData.append("judul_berita", judul);
        formData.append("keterangan_berita", keterangan);
        formData.append("link_berita", link);

        try {
          // Kirim data ke endpoint untuk menyimpan berita
          const response = await axios.post(`/administrator/berita`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          return response.data;
        } catch (error) {
          console.error("Error saving berita:", error);
          Swal.showValidationMessage("Gagal menyimpan berita!");
          return false;
        }
      }
    });

    if (formValues) {
      Swal.fire("Berhasil!", "Berita berhasil ditambahkan.", "success");
      fetchBerita(); // Refresh daftar berita
    }
  };

  return (
    <div
      className="tab-pane fade"
      id="berita-justify"
      role="tabpanel"
      aria-labelledby="berita-tab-justify"
    >
      <div className="alert alert-primary mt-3" role="alert">
        <div className="iq-alert-text">
          Kelola Berita Website <b>SMA Muhammadiyah Tasikmalaya</b>
          <br />
          <small className="text-danger font-weight-bold">
            *Silahkan tambahkan data atau edit sesuai fungsi yang tersedia.
          </small>
        </div>
      </div>

      {/* Tombol Tambah Berita */}
      <button className="btn btn-success mb-3" onClick={handleTambahBerita}>
        Tambah Berita
      </button>

      {/* Tabel untuk menampilkan daftar berita */}
      <table className="table mt-3">
        <thead>
          <tr>
            <th>No</th>
            <th>Judul</th>
            <th>Keterangan</th>
            <th>Link</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {beritaList.map((berita, index) => (
            <tr key={berita.id}>
              <td>{index + 1}</td>
              <td>{berita.judul_berita}</td>
              <td>{berita.keterangan_berita}</td>
              <td>{berita.link_berita}</td>
              <td>
                <button
                  className="btn btn-danger mt-3 ml-2"
                  onClick={() => deleteBerita(berita.id)}
                >
                  Hapus
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default WebsiteBerita;
