import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function DaftarFolder() {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchFolders = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/cloud-storage/all");
      setFolders(response.data.folders);
    } catch (error) {
      console.error("Error fetching folders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  const handleCreateFolder = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Buat Folder Baru",
      html:
        '<input id="swal-input1" class="swal2-input" placeholder="Nama Folder">' +
        '<input id="swal-input2" class="swal2-input" placeholder="Keterangan">',
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Buat",
      cancelButtonText: "Batal",
      preConfirm: () => {
        const folderName = document.getElementById("swal-input1").value;
        const keterangan = document.getElementById("swal-input2").value;
        if (!folderName) {
          Swal.showValidationMessage("Nama folder tidak boleh kosong!");
        }
        return { name: folderName, keterangan: keterangan };
      }
    });

    if (formValues) {
      try {
        await axios.post("/cloud-storage/create-folder", formValues);
        Swal.fire("Sukses", "Folder berhasil dibuat!", "success");
        fetchFolders();
      } catch (error) {
        Swal.fire("Error", "Gagal membuat folder.", "error");
      }
    }
  };

  const handleDeleteFolder = async (id) => {
    Swal.fire({
      title: "Anda yakin?",
      text: "Folder yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/cloud-storage/delete/${id}`);
          Swal.fire("Terhapus!", "Folder berhasil dihapus.", "success");
          fetchFolders();
        } catch (error) {
          Swal.fire("Error", "Gagal menghapus folder.", "error");
        }
      }
    });
  };

  const handleEditFolder = async (id, currentName, currentKeterangan) => {
    const { value: formValues } = await Swal.fire({
      title: "Edit Folder",
      html:
        '<input id="swal-input1" class="swal2-input" value="' +
        currentName +
        '" placeholder="Nama Folder">' +
        '<input id="swal-input2" class="swal2-input" value="' +
        currentKeterangan +
        '" placeholder="Keterangan">',
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Simpan",
      cancelButtonText: "Batal",
      preConfirm: () => {
        const folderName = document.getElementById("swal-input1").value;
        const keterangan = document.getElementById("swal-input2").value;

        if (!folderName) {
          Swal.showValidationMessage("Nama folder tidak boleh kosong!");
          return;
        }

        return { name: folderName, keterangan: keterangan };
      }
    });

    if (formValues) {
      const { name, keterangan } = formValues;

      try {
        await axios.put(`/cloud-storage/updateFolder/${id}`, {
          name: name,
          keterangan: keterangan
        });
        Swal.fire("Sukses", "Data folder berhasil diubah!", "success");
        fetchFolders();
      } catch (error) {
        Swal.fire("Error", "Gagal mengubah data folder.", "error");
      }
    }
  };

  const handleOpenFolder = (id) => {
    navigate(`/cloud/folder/${id}`);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card card-block card-stretch card-transparent">
          <div className="card-header d-flex justify-content-between pb-0">
            <div className="header-title">
              <h4 className="card-title">Daftar Folder</h4>
            </div>
            <div className="card-header-toolbar">
              <button className="btn btn-primary" onClick={handleCreateFolder}>
                Buat Folder
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : folders.length === 0 ? (
        <div className="col-lg-12">
          <div className="card card-body text-center">
            <p>Tidak Ada Folder yang anda miliki.</p>
          </div>
        </div>
      ) : (
        folders.map((folder) => (
          <div className="col-md-6 col-sm-6 col-lg-2" key={folder.id}>
            <div className="card card-block card-stretch card-height">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <a
                    href="#"
                    onClick={() => handleOpenFolder(folder.id)}
                    className="folder"
                  >
                    <div className="rounded mb-4">
                      <img
                        src="../assets/images/folder-1.png"
                        className="img-fluid"
                      />
                    </div>
                  </a>
                  {folder.name}
                  <div className="card-header-toolbar">
                    <div className="dropdown">
                      <span
                        className="dropdown-toggle"
                        id="dropdownMenuButton2"
                        data-toggle="dropdown"
                      >
                        <i className="ri-more-2-fill" />
                      </span>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton2"
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() =>
                            handleEditFolder(
                              folder.id,
                              folder.name,
                              folder.keterangan
                            )
                          }
                        >
                          <i className="ri-pencil-fill mr-2" />
                          Edit Folder
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => handleDeleteFolder(folder.id)}
                        >
                          <i className="ri-delete-bin-6-fill mr-2" />
                          Hapus Folder
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="#"
                  onClick={() => handleOpenFolder(folder.id)}
                  className="folder"
                >
                  <p className="mb-2">
                    <i className="fa-solid fa-file-alt text-primary mr-2 font-size-20" />{" "}
                    {folder.keterangan}
                  </p>
                  <p className="mb-0">
                    <i className="fa-solid fa-clock text-primary mr-2 font-size-20" />{" "}
                    {new Date(folder.created_at).toLocaleDateString("id-ID", {
                      day: "numeric",
                      month: "long",
                      year: "numeric"
                    })}
                  </p>
                </a>
                <center>
                  <button
                    className="btn btn-outline-primary mt-3"
                    onClick={() => handleOpenFolder(folder.id)}
                  >
                    Masuk Folder
                  </button>
                </center>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default DaftarFolder;
