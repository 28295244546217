import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function WebsitePPDB() {
  const [ppdb, setPPDB] = useState(null); // Mengelola data PPDB

  // Fungsi untuk mengambil data PPDB
  const fetchPPDB = async () => {
    try {
      const response = await axios.get("/administrator/ppdb");
      if (response.data.length > 0) {
        setPPDB(response.data[0]); // Asumsikan hanya ada satu data yang diambil
      } else {
        setPPDB(null); // Jika tidak ada data, set null
      }
    } catch (error) {
      console.error("Error fetching PPDB:", error);
    }
  };

  // Mengambil data saat komponen dimuat
  useEffect(() => {
    fetchPPDB();
  }, []);

  // Fungsi untuk menambahkan data PPDB
  const handleTambahPPDB = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Tambah Informasi PPDB",
      html: `
        <label for="swal-input1">Judul PPDB</label>
        <input id="swal-input1" class="swal2-input" placeholder="Masukkan Judul PPDB">
        <label for="swal-input2">Keterangan PPDB</label>
        <textarea id="swal-input2" class="swal2-textarea" placeholder="Keterangan PPDB"></textarea>
        <label for="swal-input3">Foto PPDB</label>
        <input id="swal-input3" class="swal2-input" type="file" placeholder="Upload Foto">`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        const judul = document.getElementById("swal-input1").value;
        const keterangan = document.getElementById("swal-input2").value;
        const foto = document.getElementById("swal-input3").files[0];

        if (!judul || !keterangan || !foto) {
          Swal.showValidationMessage("Semua kolom harus diisi!");
          return false;
        }

        const formData = new FormData();
        formData.append("judul_ppdb", judul);
        formData.append("keterangan_ppdb", keterangan);
        formData.append("foto", foto);

        try {
          // Kirim request POST ke backend dengan FormData
          const response = await axios.post(`/administrator/ppdb`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          return response.data;
        } catch (error) {
          console.error("Error saving PPDB:", error);
          Swal.showValidationMessage("Gagal menyimpan data PPDB!");
          return false;
        }
      }
    });

    if (formValues) {
      Swal.fire("Berhasil!", "Informasi PPDB berhasil ditambahkan.", "success");
      fetchPPDB(); // Refresh data
    }
  };

  // Fungsi untuk menghapus data PPDB
  const deletePPDB = async (id) => {
    const confirmDelete = await Swal.fire({
      title: "Yakin ingin menghapus?",
      text: "Anda tidak akan bisa mengembalikan data ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal"
    });

    if (confirmDelete.isConfirmed) {
      try {
        await axios.delete(`/administrator/ppdb/${id}`);
        Swal.fire("Terhapus!", "Informasi PPDB telah dihapus.", "success");
        setPPDB(null); // Reset state setelah dihapus
      } catch (error) {
        console.error("Error deleting PPDB:", error);
        Swal.fire("Error", "Gagal menghapus data.", "error");
      }
    }
  };

  return (
    <div
      className="tab-pane fade"
      id="ppdb-justify"
      role="tabpanel"
      aria-labelledby="ppdb-tab-justify"
    >
      <div className="alert alert-primary mt-3" role="alert">
        <div className="iq-alert-text">
          Kelola Informasi PPDB Website <b>SMA Muhammadiyah Tasikmalaya</b>
          <br />
          <small className="text-danger font-weight-bold">
            *Silahkan tambahkan Informasi atau edit sesuai fungsi yang tersedia.
          </small>
        </div>
      </div>

      {/* Jika data belum ada, tampilkan tombol tambah */}
      {!ppdb ? (
        <>
          <p>Silahkan tambahkan informasi PPDB.</p>
          <button className="btn btn-success mb-3" onClick={handleTambahPPDB}>
            Tambah Informasi PPDB
          </button>
        </>
      ) : (
        <>
          {/* Jika data sudah ada, tampilkan tombol hapus */}
          <div className="mt-3">
            {" "}
            <button
              className="btn btn-danger mt-3 mb-3"
              onClick={() => deletePPDB(ppdb.id)}
            >
              Hapus Informasi PPDB
            </button>
            <h4>{ppdb.judul_ppdb}</h4>
            <p>{ppdb.keterangan_ppdb}</p>
            <img
              src={ppdb.foto ? `https://smamutas.sch.id${ppdb.foto}` : "-"}
              alt="Foto PPDB"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            <br />
            <a href={ppdb.link_ppdb} target="_blank" rel="noopener noreferrer">
              {ppdb.link_ppdb}
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default WebsitePPDB;
