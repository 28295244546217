import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function AksesPegawai() {
  const [users, setUsers] = useState([]); // Data users
  const [pendidik, setPendidik] = useState([]); // Data tenaga pendidik
  const [kependidikan, setKependidikan] = useState([]); // Data tenaga kependidikan
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState(""); // Data untuk pencarian
  const [currentPage, setCurrentPage] = useState(1); // Halaman saat ini
  const dataPerPage = 10; // Batas data per halaman

  // Fetch data users, tenaga pendidik, dan kependidikan
  const fetchData = async () => {
    try {
      const usersResponse = await axios.get("/administrator/users");
      const pendidikResponse = await axios.get(
        "/administrator/tenaga-pendidik"
      );
      const kependidikanResponse = await axios.get(
        "/administrator/tenaga-kependidikan"
      );

      setUsers(usersResponse.data);
      setPendidik(pendidikResponse.data);
      setKependidikan(kependidikanResponse.data);
    } catch (error) {
      console.error("Kesalahan mengambil data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Fungsi untuk mencari nama pegawai berdasarkan kode_pegawai
  const findNamaPegawai = (kode_pegawai) => {
    const pendidikMatch = pendidik.find((p) => p.kode_pegawai === kode_pegawai);
    if (pendidikMatch) return pendidikMatch.nama;

    const kependidikanMatch = kependidikan.find(
      (k) => k.kode_pegawai === kode_pegawai
    );
    if (kependidikanMatch) return kependidikanMatch.nama;

    return "Nama tidak ditemukan";
  };

  // Fungsi untuk mengganti email
  const editEmail = async (user) => {
    const { value: newEmail } = await Swal.fire({
      title: `Edit Email untuk ${findNamaPegawai(user.kode_pegawai)}`,
      input: "email",
      inputLabel: "Masukkan Email baru",
      inputValue: user.email || "",
      inputPlaceholder: "Email baru",
      showCancelButton: true,
      confirmButtonText: "Simpan",
      cancelButtonText: "Batal"
    });

    if (newEmail) {
      try {
        await axios.put(`/administrator/users/${user.id}`, { email: newEmail });
        Swal.fire("Berhasil!", "Email berhasil diperbarui.", "success");
        fetchData(); // Fetch ulang data setelah edit
      } catch (error) {
        Swal.fire("Kesalahan", "Gagal menyimpan data.", "error");
      }
    }
  };

  // Fungsi untuk mengganti password
  const editPassword = async (user) => {
    const { value: newPassword } = await Swal.fire({
      title: `Edit Password untuk ${findNamaPegawai(user.kode_pegawai)}`,
      input: "password",
      inputLabel: "Masukkan Password baru",
      inputPlaceholder: "Password baru",
      showCancelButton: true,
      confirmButtonText: "Simpan",
      cancelButtonText: "Batal"
    });

    if (newPassword) {
      try {
        await axios.put(`/administrator/users/${user.id}`, {
          password: newPassword
        });
        Swal.fire("Berhasil!", "Password berhasil diperbarui.", "success");
        fetchData(); // Fetch ulang data setelah edit
      } catch (error) {
        Swal.fire("Kesalahan", "Gagal menyimpan data.", "error");
      }
    }
  };

  // Fungsi untuk menangani pencarian
  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset halaman ke 1 saat melakukan pencarian
  };

  // Filter dan paginasi data yang akan ditampilkan
  const filteredUsers = users.filter((user) =>
    findNamaPegawai(user.kode_pegawai)
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredUsers.slice(indexOfFirstData, indexOfLastData);

  const totalPages = Math.ceil(filteredUsers.length / dataPerPage);

  const changePage = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) {
    return <div className="spinner"></div>;
  }

  return (
    <div
      className="tab-pane fade"
      id="akses-justify"
      role="tabpanel"
      aria-labelledby="akses-tab-justify"
    >
      <div className="alert alert-primary mt-3" role="alert">
        <div className="iq-alert-text">
          Daftar Akses Pegawai <b>SMA Muhammadiyah Tasikmalaya</b>
          <br />
          <small className="text-danger font-weight-bold">
            *Silahkan tambahkan data pegawai jika akses tidak tersedia.
          </small>
        </div>
      </div>

      {/* Pencarian */}
      <div className="col-lg-2 d-flex justify-content-center">
        <i className="fa-solid fa-magnifying-glass mr-2 mt-2" />
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Cari Nama Pegawai"
          value={search}
          onChange={handleSearch}
        />
      </div>

      <div className="table-responsive">
        <table className="table table-bordered mt-3">
          <thead>
            <tr>
              <th>Nama</th>
              <th>Email</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((user) => (
              <tr key={user.id}>
                <td>{findNamaPegawai(user.kode_pegawai)}</td>
                <td>{user.email}</td>
                <td>
                  <button
                    onClick={() => editEmail(user)}
                    className="btn btn-primary mx-1"
                  >
                    Edit Email
                  </button>
                  <button
                    onClick={() => editPassword(user)}
                    className="btn btn-outline-primary mx-1"
                  >
                    Edit Password
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <nav>
          <ul className="pagination">
            {[...Array(totalPages)].map((_, index) => (
              <li
                key={index}
                className={`page-item ${
                  index + 1 === currentPage ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => changePage(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
}

export default AksesPegawai;
