import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import MainLayouts from "./Components/Layouts/MainLayouts";
import Home from "./Components/Home/Content";
import CloudStorage from "./Components/CloudStorage/Content";
import FileInFolder from "./Components/CloudStorage/FileInFolder";
import Login from "./Components/Authentication/Login";
import LockAdmin from "./Components/Authentication/LockAdmin";
import Administrators from "./Components/Administrators/Content";

axios.defaults.baseURL = "https://smamutas.sch.id/api";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "auth_token"
)}`;
axios.defaults.withCredentials = true;

function App() {
  return (
    <Router>
      <Routes>
        {/* Route tanpa MainLayouts */}
        <Route path="/login" element={<Login />} />

        {/* Routes dengan MainLayouts */}
        <Route
          path="/"
          element={
            <MainLayouts>
              <Home />
            </MainLayouts>
          }
        />
        <Route
          path="/cloud"
          element={
            <MainLayouts>
              <CloudStorage />
            </MainLayouts>
          }
        />

        <Route
          path="/cloud/folder/:id"
          element={
            <MainLayouts>
              <FileInFolder />
            </MainLayouts>
          }
        />
        {/* Administrator */}
        <Route
          path="/verify/administrator"
          element={
            <MainLayouts>
              <LockAdmin />
            </MainLayouts>
          }
        />
        <Route
          path="/administrator"
          element={
            <MainLayouts>
              <Administrators />
            </MainLayouts>
          }
        />
        {/* Tambahkan route lain yang menggunakan MainLayouts di sini */}
      </Routes>
    </Router>
  );
}

export default App;
