import React from "react";

function Changemode() {
  return (
    <div className="change-mode">
      <div className="custom-control custom-switch custom-switch-icon custom-control-inline">
        <div className="custom-switch-inner">
          <p className="mb-0"> </p>
          <input
            type="checkbox"
            className="custom-control-input"
            id="dark-mode"
            data-active="true"
          />
          <label
            className="custom-control-label"
            htmlFor="dark-mode"
            data-mode="toggle"
          >
            <span className="switch-icon-left">
              <i className="a-left" />
            </span>
            <span className="switch-icon-right">
              <i className="a-right" />
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default Changemode;
