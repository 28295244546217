import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function DataPegawai() {
  const [tenagaPendidik, setTenagaPendidik] = useState([]);
  const [tenagaKependidikan, setTenagaKependidikan] = useState([]);
  const [users, setUsers] = useState([]);
  const [activeTable, setActiveTable] = useState("pendidik");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const ITEMS_PER_PAGE = 10;

  const fetchData = async () => {
    try {
      const pendidikResponse = await axios.get(
        "/administrator/tenaga-pendidik"
      );
      const kependidikanResponse = await axios.get(
        "/administrator/tenaga-kependidikan"
      );
      const usersResponse = await axios.get("/administrator/users");

      setTenagaPendidik(pendidikResponse.data);
      setTenagaKependidikan(kependidikanResponse.data);
      setUsers(usersResponse.data);
    } catch (error) {
      console.error("Kesalahan mengambil data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const generateKodePegawai = (isPendidik, id) => {
    return isPendidik ? `SMAM-${id}` : `SMAM-TU-${id}`;
  };

  const tambahPegawai = async (isPendidik) => {
    const { value: formValues } = await Swal.fire({
      title: isPendidik
        ? "Tambah Tenaga Pendidik"
        : "Tambah Tenaga Kependidikan",
      html: `
        <label for="swal-input0">Foto</label>
        <input id="swal-input0" class="swal2-input" type="file">
        <label for="swal-input1">Nama</label>
        <input id="swal-input1" class="swal2-input" placeholder="Masukkan Nama">
        <label for="swal-input2">Tempat Lahir</label>
        <input id="swal-input2" class="swal2-input" placeholder="Tempat Lahir">
        <label for="swal-input3">Tanggal Lahir</label>
        <input id="swal-input3" class="swal2-input" type="date">
        <label for="swal-input4">Alamat</label>
        <input id="swal-input4" class="swal2-input" placeholder="Alamat">
        <label for="swal-input5">NBM</label>
        <input id="swal-input5" class="swal2-input" placeholder="NBM">
        <label for="swal-input6">Lulusan</label>
        <input id="swal-input6" class="swal2-input" placeholder="Lulusan">
        <label for="swal-input7">TMT</label>
        <input id="swal-input7" class="swal2-input" type="date">
        <label for="swal-input8">Jabatan</label>
        <input id="swal-input8" class="swal2-input" placeholder="Jabatan">
        <label for="swal-input9">Mata Pelajaran (untuk pendidik)</label>
        <input id="swal-input9" class="swal2-input" ${
          isPendidik ? "" : "hidden"
        }>
      `,
      focusConfirm: false,
      preConfirm: () => {
        const fileInput = document.getElementById("swal-input0");
        const foto = fileInput.files[0];
        return {
          foto,
          nama: document.getElementById("swal-input1").value,
          tempat_lahir: document.getElementById("swal-input2").value,
          tanggal_lahir: document.getElementById("swal-input3").value,
          alamat: document.getElementById("swal-input4").value,
          nbm: document.getElementById("swal-input5").value,
          lulusan: document.getElementById("swal-input6").value,
          tmt: document.getElementById("swal-input7").value,
          jabatan: document.getElementById("swal-input8").value,
          mata_pelajaran: isPendidik
            ? document.getElementById("swal-input9").value
            : undefined
        };
      }
    });

    if (formValues) {
      const formData = new FormData();
      if (formValues.foto) {
        formData.append("foto", formValues.foto);
      }
      formData.append("nama", formValues.nama);
      formData.append("tempat_lahir", formValues.tempat_lahir);
      formData.append("tanggal_lahir", formValues.tanggal_lahir);
      formData.append("alamat", formValues.alamat);
      formData.append("nbm", formValues.nbm);
      formData.append("lulusan", formValues.lulusan);
      formData.append("tmt", formValues.tmt);
      formData.append("jabatan", formValues.jabatan);

      const kode_pegawai = generateKodePegawai(
        isPendidik,
        isPendidik ? tenagaPendidik.length + 1 : tenagaKependidikan.length + 1
      );

      formData.append("kode_pegawai", kode_pegawai);

      if (isPendidik) {
        formData.append("mata_pelajaran", formValues.mata_pelajaran);
        await axios.post("/administrator/tenaga-pendidik", formData);
      } else {
        await axios.post("/administrator/tenaga-kependidikan", formData);
      }

      Swal.fire("Success", "Data pegawai berhasil ditambahkan", "success");
      fetchData(); // Muat ulang data setelah penambahan
    }
  };

  const editPegawai = async (pegawai) => {
    let sectionData = pegawai;
    let endpoint = "";
    let formHtml = "";
    const isPendidik = !!pegawai.mata_pelajaran;

    // Tentukan endpoint dan form berdasarkan tipe pegawai
    if (isPendidik) {
      // Tenaga Pendidik
      endpoint = `/administrator/tenaga-pendidik/${sectionData.id}`;
      formHtml = `
        <label for="swal-input1">Nama</label>
        <input id="swal-input1" class="swal2-input" placeholder="Masukkan Nama" value="${
          sectionData.nama || ""
        }">
        <label for="swal-input2">Tempat Lahir</label>
        <input id="swal-input2" class="swal2-input" placeholder="Tempat Lahir" value="${
          sectionData.tempat_lahir || ""
        }">
        <label for="swal-input3">Tanggal Lahir</label>
        <input id="swal-input3" class="swal2-input" type="date" value="${
          sectionData.tanggal_lahir || ""
        }">
        <label for="swal-input4">Alamat</label>
        <input id="swal-input4" class="swal2-input" placeholder="Alamat" value="${
          sectionData.alamat || ""
        }">
        <label for="swal-input5">NBM</label>
        <input id="swal-input5" class="swal2-input" placeholder="NBM" value="${
          sectionData.nbm || ""
        }">
        <label for="swal-input6">Lulusan</label>
        <input id="swal-input6" class="swal2-input" placeholder="Lulusan" value="${
          sectionData.lulusan || ""
        }">
        <label for="swal-input7">TMT</label>
        <input id="swal-input7" class="swal2-input" type="date" value="${
          sectionData.tmt || ""
        }">
        <label for="swal-input8">Jabatan</label>
        <input id="swal-input8" class="swal2-input" placeholder="Jabatan" value="${
          sectionData.jabatan || ""
        }">
        <label for="swal-input9">Mata Pelajaran</label>
        <input id="swal-input9" class="swal2-input" placeholder="Mata Pelajaran" value="${
          sectionData.mata_pelajaran || ""
        }">
      `;
    } else {
      // Tenaga Kependidikan
      endpoint = `/administrator/tenaga-kependidikan/${sectionData.id}`;
      formHtml = `
        <label for="swal-input1">Nama</label>
        <input id="swal-input1" class="swal2-input" placeholder="Masukkan Nama" value="${
          sectionData.nama || ""
        }">
        <label for="swal-input2">Tempat Lahir</label>
        <input id="swal-input2" class="swal2-input" placeholder="Tempat Lahir" value="${
          sectionData.tempat_lahir || ""
        }">
        <label for="swal-input3">Tanggal Lahir</label>
        <input id="swal-input3" class="swal2-input" type="date" value="${
          sectionData.tanggal_lahir || ""
        }">
        <label for="swal-input4">Alamat</label>
        <input id="swal-input4" class="swal2-input" placeholder="Alamat" value="${
          sectionData.alamat || ""
        }">
        <label for="swal-input5">NBM</label>
        <input id="swal-input5" class="swal2-input" placeholder="NBM" value="${
          sectionData.nbm || ""
        }">
        <label for="swal-input6">Lulusan</label>
        <input id="swal-input6" class="swal2-input" placeholder="Lulusan" value="${
          sectionData.lulusan || ""
        }">
        <label for="swal-input7">TMT</label>
        <input id="swal-input7" class="swal2-input" type="date" value="${
          sectionData.tmt || ""
        }">
        <label for="swal-input8">Jabatan</label>
        <input id="swal-input8" class="swal2-input" placeholder="Jabatan" value="${
          sectionData.jabatan || ""
        }">
      `;
    }

    // Tampilkan form edit menggunakan Swal
    const { value: formValues } = await Swal.fire({
      title: isPendidik
        ? `Edit Tenaga Pendidik (${sectionData.nama})`
        : `Edit Tenaga Kependidikan (${sectionData.nama})`,
      html: formHtml,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Simpan",
      cancelButtonText: "Batal",
      preConfirm: () => {
        // Ambil nilai form
        const nama = document.getElementById("swal-input1").value;
        const tempat_lahir = document.getElementById("swal-input2").value;
        const tanggal_lahir = document.getElementById("swal-input3").value;
        const alamat = document.getElementById("swal-input4").value;
        const nbm = document.getElementById("swal-input5")?.value || "";
        const lulusan = document.getElementById("swal-input6")?.value || "";
        const tmt = document.getElementById("swal-input7")?.value || "";
        const jabatan = document.getElementById("swal-input8")?.value || "";
        const mata_pelajaran = isPendidik
          ? document.getElementById("swal-input9").value.trim()
          : undefined;

        // Tentukan data yang akan dikirim
        sectionData = {
          ...sectionData,
          nama,
          tempat_lahir,
          tanggal_lahir,
          alamat,
          nbm,
          lulusan,
          tmt,
          jabatan,
          mata_pelajaran
        };

        return sectionData;
      }
    });

    if (!formValues) return; // Jika dibatalkan, hentikan

    // Lakukan permintaan update data ke API
    try {
      await axios.put(endpoint, sectionData);
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Data berhasil diperbarui!"
      });
      fetchData(); // Ambil ulang data setelah edit berhasil
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Kesalahan",
        text: "Gagal memperbarui data!"
      });
    }
  };

  const updateFotoPegawai = async (pegawai) => {
    const { kode_pegawai } = pegawai;

    // menampilkan file foto
    const { value: file } = await Swal.fire({
      title: `Update Foto Pegawai (${pegawai.nama})`,
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload Foto Pegawai"
      },
      showCancelButton: true,
      confirmButtonText: "Upload",
      cancelButtonText: "Batal"
    });

    if (file) {
      const formData = new FormData();
      formData.append("foto", file);
      formData.append("kode_pegawai", kode_pegawai);

      try {
        // mengirim foto ke backend
        await axios.post("/administrator/ubah-foto", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        Swal.fire("Success", "Foto berhasil diperbarui", "success");
        fetchData(); // Refresh data setelah berhasil
      } catch (error) {
        Swal.fire("Kesalahan", "Gagal mengunggah foto", "error");
      }
    }
  };

  const buatAkses = async (pegawai) => {
    const { kode_pegawai, nama } = pegawai;
    const userExists = users.some((user) => user.kode_pegawai === kode_pegawai);

    if (userExists) {
      Swal.fire("Info", "Akses untuk pegawai ini sudah ada.", "info");
    } else {
      try {
        const email = `${nama.split(" ")[0].toLowerCase()}@smamutas.sch.id`;
        const password = "smam123";
        await axios.post("/administrator/users", {
          kode_pegawai,
          email,
          password
        });
        Swal.fire("Berhasil!", "Akses berhasil dibuat.", "success");
        fetchData();
      } catch (error) {
        Swal.fire("Kesalahan", "Gagal membuat akses.", "error");
      }
    }
  };

  const hapusPegawai = async (pegawai) => {
    // URL untuk hapus pegawai dari tabel tenaga pendidik atau tenaga kependidikan
    const pegawaiUrl =
      activeTable === "pendidik"
        ? `/administrator/tenaga-pendidik/${pegawai.id}`
        : `/administrator/tenaga-kependidikan/${pegawai.id}`;

    // Cari user yang memiliki kode_pegawai yang sama
    const user = users.find(
      (user) => user.kode_pegawai === pegawai.kode_pegawai
    );
    const userUrl = user ? `/administrator/users/${user.id}` : null;

    // Konfirmasi penghapusan dengan SweetAlert
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Hapus data dari tabel tenaga pendidik atau tenaga kependidikan
          await axios.delete(pegawaiUrl);

          // Jika user dengan kode_pegawai yang sama ditemukan, hapus juga dari tabel users
          if (userUrl) {
            await axios.delete(userUrl);
          }

          Swal.fire(
            "Berhasil!",
            "Data pegawai dan akun pengguna berhasil dihapus.",
            "success"
          );
          fetchData(); // Muat ulang data setelah penghapusan
        } catch (error) {
          Swal.fire("Kesalahan", "Gagal menghapus data.", "error");
        }
      }
    });
  };

  const activeData =
    activeTable === "pendidik" ? tenagaPendidik : tenagaKependidikan;

  const filteredPegawai = activeData.filter((p) =>
    p.nama.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalPages = Math.ceil(filteredPegawai.length / ITEMS_PER_PAGE);
  const paginatedPegawai = filteredPegawai.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  if (isLoading) {
    return <div className="spinner"></div>;
  }

  return (
    <div
      className="tab-pane fade"
      id="pegawai-justify"
      role="tabpanel"
      aria-labelledby="pegawai-tab-justify"
    >
      <div className="mb-3">
        <button
          className={`btn mb-3 ${
            activeTable === "pendidik" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => setActiveTable("pendidik")}
        >
          Data Tenaga Pendidik
        </button>{" "}
        <button
          className={`btn mb-3 ${
            activeTable === "kependidikan"
              ? "btn-primary"
              : "btn-outline-primary"
          }`}
          onClick={() => setActiveTable("kependidikan")}
        >
          Data Tenaga Kependidikan
        </button>
      </div>

      <div className="alert alert-primary mt-3" role="alert">
        <div className="iq-alert-text">
          Daftar Data Pegawai <b>SMA Muhammadiyah Tasikmalaya</b>
          <br />
          <small className="text-danger font-weight-bold">
            *Silahkan memilih menu Tenaga Pendidik atau Tenaga Kependidikan
          </small>
        </div>
      </div>

      <button
        className="btn btn-primary mb-3"
        onClick={() => tambahPegawai(activeTable === "pendidik")}
      >
        {activeTable === "pendidik"
          ? "Tambah Data Tenaga Pendidik"
          : "Tambah Data Tenaga Kependidikan"}
      </button>
      <input
        type="text"
        placeholder="Cari pegawai..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="form-control mb-3"
      />

      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Foto</th>
              <th>Nama</th>
              <th>Tempat Lahir</th>
              <th>Tanggal Lahir</th>
              <th>Alamat</th>
              <th>NBM</th>
              <th>Lulusan</th>
              <th>TMT</th>
              <th>Jabatan</th>
              {activeTable === "pendidik" && <th>Mata Pelajaran</th>}
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            {paginatedPegawai.map((pegawai) => (
              <tr key={pegawai.id}>
                <td>
                  <img
                    src={
                      pegawai.foto
                        ? `https://smamutas.sch.id${pegawai.foto}`
                        : "https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg"
                    }
                    alt={pegawai.nama}
                    width={70}
                  />
                </td>
                <td>{pegawai.nama || "-"}</td>
                <td>{pegawai.tempat_lahir || "-"}</td>
                <td>{pegawai.tanggal_lahir || "-"}</td>
                <td>{pegawai.alamat || "-"}</td>
                <td>{pegawai.nbm || "-"}</td>
                <td>{pegawai.lulusan || "-"}</td>
                <td>{pegawai.tmt || "-"}</td>
                <td>{pegawai.jabatan || "-"}</td>
                {activeTable === "pendidik" && (
                  <td>{pegawai.mata_pelajaran || "-"}</td>
                )}
                <td>
                  {!users.some(
                    (user) => user.kode_pegawai === pegawai.kode_pegawai
                  ) && (
                    <button
                      className="btn btn-success mb-2"
                      onClick={() => buatAkses(pegawai)}
                    >
                      Buat Akses
                    </button>
                  )}
                  <button
                    className="btn btn-outline-primary ml-2 mb-2"
                    onClick={() => updateFotoPegawai(pegawai)}
                  >
                    Ganti Foto
                  </button>
                  <button
                    className="btn btn-primary ml-2 mb-2"
                    onClick={() => editPegawai(pegawai)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger ml-2 mb-2"
                    onClick={() => hapusPegawai(pegawai)}
                  >
                    Hapus
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        {[...Array(totalPages).keys()].map((page) => (
          <button
            key={page}
            className={`btn ${
              currentPage === page + 1 ? "btn-primary" : "btn-light"
            }`}
            onClick={() => setCurrentPage(page + 1)}
          >
            {page + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default DataPegawai;
