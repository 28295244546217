import React from "react";

function Helper() {
  return (
    <li className="nav-item nav-icon dropdown">
      <a
        href="#"
        className="search-toggle dropdown-toggle"
        id="dropdownMenuButton01"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="ri-question-line" />
      </a>
      <div
        className="iq-sub-dropdown dropdown-menu"
        aria-labelledby="dropdownMenuButton01"
      >
        <div className="card shadow-none m-0">
          <div className="card-body p-0">
            <div className="p-3">
              <a href="#" className="iq-sub-card pt-0">
                <i className="ri-questionnaire-line" />
                Bantuan
              </a>
              <a href="#" className="iq-sub-card">
                <i className="ri-recycle-line" />
                Pelatihan
              </a>
              <a href="#" className="iq-sub-card">
                <i className="ri-refresh-line" />
                Pembaruan
              </a>
              <a href="#" className="iq-sub-card">
                <i className="ri-service-line" />
                Ketentuan dan Kebijakan
              </a>
              <a href="#" className="iq-sub-card">
                <i className="ri-feedback-line" />
                Kirim Masukan
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default Helper;
