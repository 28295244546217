import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function WebsitePapanPrestasi() {
  const [papanPrestasiList, setPapanPrestasiList] = useState([]);

  // Fungsi untuk mengambil daftar Papan Prestasi
  const fetchPapanPrestasi = async () => {
    try {
      const response = await axios.get("/administrator/prestasi");
      setPapanPrestasiList(response.data);
    } catch (error) {
      console.error("Error fetching Papan Prestasi:", error);
    }
  };

  // Mengambil data saat komponen dimuat
  useEffect(() => {
    fetchPapanPrestasi();
  }, []);

  // Fungsi untuk menambahkan Papan Prestasi
  const handleTambahPapanPrestasi = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Tambah Papan Prestasi Baru",
      html: `
        <label for="swal-input0">Foto</label>
        <input id="swal-input0" class="swal2-input" type="file">
        <label for="swal-input1">Link Instagram</label>
        <input id="swal-input1" class="swal2-input" placeholder="Masukkan Link Instagram">`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        const linkInstagram = document.getElementById("swal-input1").value;
        const foto = document.getElementById("swal-input0").files[0]; // Mengambil file foto

        if (!linkInstagram || !foto) {
          Swal.showValidationMessage("Semua kolom harus diisi!");
          return false;
        }

        const formData = new FormData();
        formData.append("link_instagram", linkInstagram);
        formData.append("foto", foto);

        try {
          // Kirim data ke endpoint untuk menyimpan Papan Prestasi
          const response = await axios.post(
            `/administrator/prestasi`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          );

          return response.data;
        } catch (error) {
          console.error("Error saving Papan Prestasi:", error);
          Swal.showValidationMessage("Gagal menyimpan data Prestasi!");
          return false;
        }
      }
    });

    if (formValues) {
      Swal.fire("Berhasil!", "Papan Prestasi berhasil ditambahkan.", "success");
      fetchPapanPrestasi(); // Refresh data setelah menambah
    }
  };

  // Fungsi untuk menghapus Papan Prestasi
  const deletePapanPrestasi = async (id) => {
    const confirmDelete = await Swal.fire({
      title: "Yakin ingin menghapus?",
      text: "Anda tidak akan bisa mengembalikan data ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal"
    });

    if (confirmDelete.isConfirmed) {
      try {
        await axios.delete(`/administrator/prestasi/${id}`);
        Swal.fire("Terhapus!", "Papan Prestasi telah dihapus.", "success");
        fetchPapanPrestasi(); // Refresh data setelah menghapus
      } catch (error) {
        console.error("Error deleting Papan Prestasi:", error);
        Swal.fire("Error", "Gagal menghapus data.", "error");
      }
    }
  };

  return (
    <div
      className="tab-pane fade"
      id="prestasi-justify"
      role="tabpanel"
      aria-labelledby="prestasi-tab-justify"
    >
      <div className="alert alert-primary mt-3" role="alert">
        <div className="iq-alert-text">
          Kelola Papan Prestasi Website <b>SMA Muhammadiyah Tasikmalaya</b>
          <br />
          <small className="text-danger font-weight-bold">
            *Silahkan tambahkan data atau edit sesuai fungsi yang tersedia.
          </small>
        </div>
      </div>

      {/* Tombol Tambah Papan Prestasi */}
      <button
        className="btn btn-success mb-3"
        onClick={handleTambahPapanPrestasi}
      >
        Tambah Data Papan Prestasi
      </button>

      {/* Tabel untuk menampilkan daftar Papan Prestasi */}
      <table className="table mt-3">
        <thead>
          <tr>
            <th>No</th>
            <th>Link Instagram</th>
            <th>Foto</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {papanPrestasiList.map((prestasi, index) => (
            <tr key={prestasi.id}>
              <td>{index + 1}</td>
              <td>{prestasi.link_instagram}</td>
              <td>
                <img
                  src={
                    prestasi.foto
                      ? `https://smamutas.sch.id${prestasi.foto}`
                      : "-"
                  }
                  alt="Foto Papan Prestasi"
                  style={{ maxWidth: "100px", height: "auto" }}
                />
              </td>
              <td>
                <button
                  className="btn btn-danger mt-3 ml-2"
                  onClick={() => deletePapanPrestasi(prestasi.id)}
                >
                  Hapus
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default WebsitePapanPrestasi;
