import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Komponen untuk mengelola Petugas Piket
function DataPiket() {
  const [petugasPiket, setPetugasPiket] = useState([]);
  const [tenagaPendidik, setTenagaPendidik] = useState([]);
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    getPetugasPiket();
    getTenagaPendidik();
  }, []);

  // Mendapatkan data petugas piket dari backend
  const getPetugasPiket = async () => {
    try {
      const response = await axios.get("/administrator/petugas-piket");
      setPetugasPiket(response.data);
    } catch (error) {
      console.error("Error fetching petugas piket:", error);
    }
  };

  // Mendapatkan data tenaga pendidik dari backend
  const getTenagaPendidik = async () => {
    try {
      const response = await axios.get("/administrator/tenaga-pendidik");
      setTenagaPendidik(response.data);
    } catch (error) {
      console.error("Error fetching tenaga pendidik:", error);
    }
  };

  // Menangani perubahan pada data lokal
  const handleChange = (id, field, value) => {
    setEditedData((prevData) => ({
      ...prevData,
      [id]: {
        ...prevData[id],
        [field]: value
      }
    }));
  };

  // Menyimpan perubahan petugas piket
  const handleSavePetugas = async (id) => {
    try {
      const updatedPetugas = editedData[id];
      if (!updatedPetugas) return;

      await axios.put(`/administrator/petugas-piket/${id}`, {
        kode_pegawai: updatedPetugas.kode_pegawai,
        kontak: updatedPetugas.kontak
      });

      Swal.fire({
        title: "Sukses!",
        text: "Petugas piket berhasil diperbarui!",
        icon: "success",
        confirmButtonText: "OK"
      });

      getPetugasPiket(); // Reload data setelah update
      setEditedData((prevData) => ({
        ...prevData,
        [id]: undefined
      }));
    } catch (error) {
      Swal.fire({
        title: "Gagal!",
        text: "Terjadi kesalahan saat menyimpan data.",
        icon: "error",
        confirmButtonText: "OK"
      });
    }
  };

  const days = ["Senin", "Selasa", "Rabu", "Kamis", "Jumat"];

  return (
    <div
      className="tab-pane fade"
      id="piket-justify"
      role="tabpanel"
      aria-labelledby="piket-tab-justify"
    >
      <div className="alert alert-primary mt-3" role="alert">
        <div className="iq-alert-text">
          Daftar Data Petugas Piket <b>SMA Muhammadiyah Tasikmalaya</b>
          <br />
          <small className="text-danger font-weight-bold">
            *Silahkan memilih petugas dan isi kontak
          </small>
        </div>
      </div>

      {/* Tabel petugas piket untuk setiap hari */}
      {days.map((day) => (
        <div key={day}>
          <div className="table-responsive">
            <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th>Petugas Piket {day}</th>
                  <th>Kontak</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {petugasPiket
                  .filter((item) => item.hari === day)
                  .map((item) => (
                    <tr key={item.id}>
                      <td>
                        <select
                          className="form-select"
                          value={
                            editedData[item.id]?.kode_pegawai ||
                            item.kode_pegawai
                          }
                          onChange={(e) =>
                            handleChange(
                              item.id,
                              "kode_pegawai",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Pilih Guru</option>
                          {tenagaPendidik.map((guru) => (
                            <option
                              key={guru.kode_pegawai}
                              value={guru.kode_pegawai}
                            >
                              {guru.nama}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={editedData[item.id]?.kontak || item.kontak}
                          onChange={(e) =>
                            handleChange(item.id, "kontak", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleSavePetugas(item.id)}
                        >
                          Save
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
}

export default DataPiket;
