import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie"; // Pastikan sudah menginstall js-cookie: npm install js-cookie
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2"; // Menggunakan Swal untuk loading dan notifikasi

axios.defaults.withCredentials = true;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  // Cek jika pengguna sudah terautentikasi
  const token = localStorage.getItem("auth_token");
  if (token) {
    return <Navigate to="/" />; // Jika sudah terautentikasi, redirect ke halaman utama
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Tampilkan loading ketika proses login dimulai
      Swal.fire({
        title: "Sedang memproses...",
        text: "Mohon tunggu...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      await axios.get("/sanctum/csrf-cookie"); // Ambil cookie CSRF
      const csrfToken = Cookies.get("XSRF-TOKEN");

      // Kirim permintaan login ke server
      const response = await axios.post(
        "/login",
        { email, password },
        {
          headers: { "X-XSRF-TOKEN": csrfToken }
        }
      );

      // Simpan token dan data user di localStorage
      localStorage.setItem("auth_token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));

      // Tampilkan pesan sukses dan redirect ke halaman utama
      Swal.fire({
        title: "Berhasil",
        text: "Anda berhasil masuk!",
        icon: "success"
      }).then(() => {
        window.location.href = "/"; // Redirect setelah login berhasil
      });
    } catch (err) {
      console.error("Login gagal:", err);

      // Tampilkan pesan kesalahan berdasarkan kode status
      const errorMessage =
        err.response?.status === 401
          ? "Email atau password salah."
          : err.response?.status === 403
          ? "User ini sudah login di perangkat lain."
          : "Terjadi kesalahan. Coba lagi nanti.";

      setError(errorMessage);
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error"
      });
    }
  };

  return (
    <div className="wrapper">
      <section className="login-content">
        <div className="container h-100">
          <div className="row justify-content-center align-items-center height-self-center">
            <div className="col-md-5 col-sm-12 col-12 align-self-center">
              <div className="sign-user_card">
                <img
                  src="https://smamutas.sch.id/assets/img/logo.png"
                  className="rounded-normal m-2"
                  width={150}
                  alt="logo"
                />
                <h3 className="text-primary font-weight-bold">Masuk</h3>
                <h5 className="text-primary font-weight-bold mb-3">
                  PUSAT SISTEM INFORMASI GURU
                </h5>
                {error && <p className="text-danger">{error}</p>}
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="floating-label form-group">
                        <input
                          className="floating-input form-control"
                          type="email"
                          placeholder=" "
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label>Email</label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="floating-label form-group">
                        <input
                          className="floating-input form-control"
                          type="password"
                          placeholder=" "
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label>Password</label>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary">
                          Masuk
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <p className="mt-3">
                  Lupa password?{" "}
                  <a
                    href="auth-sign-up.html"
                    className="text-primary font-weight-bold"
                  >
                    Hubungi Operator
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
