import React from "react";

function Searchbar() {
  return (
    <div className="iq-search-bar device-search">
      <form>
        <div className="input-prepend input-append">
          <div className="btn-group">
            <label className="dropdown-toggle searchbox" data-toggle="dropdown">
              <input
                className="dropdown-toggle search-query text search-input"
                type="text"
                placeholder="Ketik di sini untuk mencari..."
              />
              <span className="search-replace" />
              <a className="search-link" href="#">
                <i className="ri-search-line" />
              </a>
              <span className="caret">{/*icon*/}</span>
            </label>
            <ul className="dropdown-menu">
              <li>
                <a href="#">
                  <div className="item">
                    <i className="far fa-file-pdf bg-info" />
                    PDF
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div className="item">
                    <i className="far fa-file-alt bg-primary" />
                    Dokumen
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div className="item">
                    <i className="far fa-file-excel bg-success" />
                    Spreadsheet
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div className="item">
                    <i className="far fa-file-powerpoint bg-danger" />
                    Presentasi
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div className="item">
                    <i className="far fa-file-image bg-warning" />
                    Foto &amp; Gambar
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div className="item">
                    <i className="far fa-file-video bg-info" />
                    Video
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Searchbar;
