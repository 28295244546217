import React, { useState, useEffect } from "react";
import axios from "axios";

function JadwalGuru() {
  const [jadwal, setJadwal] = useState(null);
  const [error, setError] = useState(null);
  const [kodePegawai, setKodePegawai] = useState(null);

  // Menyimpan token dari localStorage
  const token = localStorage.getItem("auth_token");

  // Mengecek hari saat ini
  const getCurrentDay = () => {
    const currentDate = new Date();
    const day = currentDate.getDay(); // 0 (Minggu) hingga 6 (Sabtu)
    return day;
  };

  // Fetch data guru dari endpoint /pegawai
  const fetchKodePegawai = async () => {
    try {
      const response = await axios.get("/pegawai", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Mengambil kode_pegawai dari response
      setKodePegawai(response.data.kode_pegawai);
    } catch (err) {
      setError("Gagal memuat data pegawai.");
    }
  };

  // Fetch jadwal berdasarkan kode_pegawai
  const fetchJadwal = async (kodePegawai) => {
    try {
      const day = getCurrentDay();
      if (day === 0 || day === 6) {
        setJadwal({
          waktu: null,
          mapel: "Ini hari Sabtu/Minggu, Tidak ada jadwal."
        });
      } else {
        // Mengirimkan request ke API jadwal dengan kode_pegawai
        const response = await axios.get("/jadwal-guru/terdekat", {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            kode_pegawai: kodePegawai // Menggunakan kode_pegawai
          }
        });

        if (response.data.jadwal) {
          // Memeriksa apakah kode pegawai cocok dengan slot jadwal
          const dataJadwal = response.data.jadwal;
          let kelas = "";

          if (dataJadwal.x_1 === kodePegawai) kelas = "Kelas X 1";
          else if (dataJadwal.x_2 === kodePegawai) kelas = "Kelas X 2";
          else if (dataJadwal.x_3 === kodePegawai) kelas = "Kelas X 3";
          else if (dataJadwal.x_4 === kodePegawai) kelas = "Kelas X 4";
          else if (dataJadwal.xi_mipa === kodePegawai) kelas = "Kelas XI MIPA";
          else if (dataJadwal.xi_ips_1 === kodePegawai)
            kelas = "Kelas XI IPS 1";
          else if (dataJadwal.xi_ips_2 === kodePegawai)
            kelas = "Kelas XI IPS 2";
          else if (dataJadwal.xii_mipa === kodePegawai)
            kelas = "Kelas XII MIPA";
          else if (dataJadwal.xii_ips_1 === kodePegawai)
            kelas = "Kelas XII IPS 1";
          else if (dataJadwal.xii_ips_2 === kodePegawai)
            kelas = "Kelas XII IPS 2";

          setJadwal({
            waktu: dataJadwal.waktu,
            kelas: kelas,
            mapel: kelas ? "Ada jadwal" : "Tidak ada jadwal."
          });
        } else {
          setJadwal({
            kelas: null,
            waktu: null,
            mapel: "Tidak ada jadwal."
          });
        }
      }
    } catch (err) {
      setError("Gagal memuat jadwal.");
    }
  };

  useEffect(() => {
    // Memanggil fetchKodePegawai untuk mendapatkan kode_pegawai
    fetchKodePegawai();
  }, [token]);

  useEffect(() => {
    // Memanggil fetchJadwal hanya jika kode_guru sudah ada
    if (kodePegawai) {
      fetchJadwal(kodePegawai);
    }
  }, [kodePegawai]);

  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card card-block card-stretch card-height">
        <div className="card-body text-center">
          <h5 className="mb-3 text-primary font-weight-bold">
            Jadwal Terdekat
          </h5>
          <svg
            width={60}
            height={48}
            fill="orange"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M128 0c13.3 0 24 10.7 24 24l0 40 144 0 0-40c0-13.3 10.7-24 24-24s24 10.7 24 24l0 40 40 0c35.3 0 64 28.7 64 64l0 16 0 48 0 256c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 192l0-48 0-16C0 92.7 28.7 64 64 64l40 0 0-40c0-13.3 10.7-24 24-24zM400 192L48 192l0 256c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-256zM329 297L217 409c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 95-95c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
          </svg>
          <h6 className="mb-2 mt-4">
            {error ? (
              <span className="text-danger">{error}</span>
            ) : jadwal ? (
              jadwal.mapel === "Tidak ada jadwal." ||
              jadwal.mapel === "Ini hari Sabtu/Minggu, Tidak ada jadwal." ? (
                <span>{jadwal.mapel}</span>
              ) : (
                <>
                  {jadwal.mapel} di {jadwal.kelas}
                  <br />
                  Jam {jadwal.waktu}
                </>
              )
            ) : (
              <span className="loader"></span>
            )}
          </h6>
        </div>
      </div>
    </div>
  );
}

export default JadwalGuru;
