import React from "react";
import TerakhirDitambahkan from "./Components/TerakhirDitambahkan";
import DaftarFolder from "./Components/DaftarFolder";
import DaftarFile from "./Components/DaftarFile";
import ProtectedRoute from "../Authentication/ProtectedRoute";

function Content() {
  return (
    <ProtectedRoute>
      <div className="content-page">
        <div className="container-fluid">
          <DaftarFolder />
          <DaftarFile />
          <TerakhirDitambahkan />
        </div>
      </div>
    </ProtectedRoute>
  );
}

export default Content;
