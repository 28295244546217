import React, { useState, useEffect } from "react";
import axios from "axios";

// Komponen untuk menampilkan jadwal tiap kelas
function JadwalItem({ keterangan, guru, mapel }) {
  return (
    <td>
      {keterangan ? (
        <span>{keterangan}</span> // Menampilkan keterangan seperti "Istirahat" atau "Ekskul"
      ) : guru ? (
        <span>
          {guru} ({mapel})
        </span>
      ) : (
        <span>-</span> // Tampilkan "-" jika tidak ada guru atau mapel
      )}
    </td>
  );
}

function JadwalFull() {
  const [jadwal, setJadwal] = useState([]);
  const [error, setError] = useState(null);
  const [hariIni, setHariIni] = useState("");

  // Mendapatkan hari ini
  useEffect(() => {
    const hariArray = [
      "MINGGU",
      "SENIN",
      "SELASA",
      "RABU",
      "KAMIS",
      "JUMAT",
      "SABTU"
    ];
    const tanggal = new Date();
    setHariIni(hariArray[tanggal.getDay()]);
  }, []);

  // Mengambil data jadwal dari API
  useEffect(() => {
    const fetchJadwal = async () => {
      try {
        const response = await axios.get("/jadwal-pelajaran");
        if (response.data) {
          setJadwal(response.data);
        } else {
          setJadwal([]); // Set jadwal ke array kosong jika tidak ada data
        }
      } catch (err) {
        setError("Gagal memuat data jadwal.");
      }
    };

    fetchJadwal();
  }, []);

  // Filter jadwal berdasarkan hari ini
  const jadwalHariIni = jadwal.filter((item) => item.hari === hariIni);

  return (
    <div className="col-12">
      <div className="card card-block card-stretch card-height">
        <div className="card-body">
          <h5 className="mb-3 text-primary font-weight-bold text-center">
            Jadwal Pembelajaran Hari {hariIni}
          </h5>
          {error ? (
            <p className="text-danger text-center">{error}</p>
          ) : jadwalHariIni.length > 0 ? (
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Jam</th>
                  <th>Waktu</th>
                  <th>Kelas X-1</th>
                  <th>Kelas X-2</th>
                  <th>Kelas X-3</th>
                  <th>Kelas XI MIPA</th>
                  <th>Kelas XI IPS-1</th>
                  <th>Kelas XII MIPA</th>
                  <th>Kelas XII IPS-1</th>
                </tr>
              </thead>
              <tbody>
                {jadwalHariIni.map((item) => (
                  <tr key={item.id}>
                    <td>{item.jam_ke}</td>
                    <td>{item.waktu}</td>
                    <JadwalItem
                      keterangan={item.keterangan}
                      guru={item.x_1_guru}
                      mapel={item.x_1_mapel}
                    />
                    <JadwalItem
                      keterangan={item.keterangan}
                      guru={item.x_2_guru}
                      mapel={item.x_2_mapel}
                    />
                    <JadwalItem
                      keterangan={item.keterangan}
                      guru={item.x_3_guru}
                      mapel={item.x_3_mapel}
                    />
                    <JadwalItem
                      keterangan={item.keterangan}
                      guru={item.xi_mipa_guru}
                      mapel={item.xi_mipa_mapel}
                    />
                    <JadwalItem
                      keterangan={item.keterangan}
                      guru={item.xi_ips_1_guru}
                      mapel={item.xi_ips_1_mapel}
                    />
                    <JadwalItem
                      keterangan={item.keterangan}
                      guru={item.xii_mipa_guru}
                      mapel={item.xii_mipa_mapel}
                    />
                    <JadwalItem
                      keterangan={item.keterangan}
                      guru={item.xii_ips_1_guru}
                      mapel={item.xii_ips_1_mapel}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center">Tidak ada jadwal untuk hari ini.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default JadwalFull;
