import React, { useState, useEffect } from "react";

function Banner() {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      };
      setCurrentTime(now.toLocaleDateString("id-ID", options));
    };

    // Memperbarui waktu setiap detik
    const timerId = setInterval(updateClock, 1000);

    // Membersihkan interval ketika komponen di-unmount
    return () => clearInterval(timerId);
  }, []);

  return (
    <div className="col-lg-12">
      <div className="profile-image position-relative mb-3">
        <center>
          <p className="d-md-block d-none">
            <b className="">{currentTime}</b>
          </p>
          <small className="d-sm-block d-md-none">
            <b>{currentTime}</b>
          </small>
          <img
            src="https://smamutas.sch.id/assets/img/banner-3.png"
            className="img-fluid rounded"
            alt=""
          />
        </center>
      </div>
    </div>
  );
}

export default Banner;
