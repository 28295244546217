import React from "react";

function Menu() {
  return (
    <ul
      className="nav nav-tabs justify-content-center"
      id="administratorTab"
      role="tablist"
    >
      <li className="nav-item">
        <a
          className="nav-link active"
          id="home-tab-justify"
          data-toggle="tab"
          href="#home-justify"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          Halaman Utama
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          id="berita-tab-justify"
          data-toggle="tab"
          href="#berita-justify"
          role="tab"
          aria-controls="berita"
          aria-selected="false"
        >
          Kelola Berita Website
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          id="ppdb-tab-justify"
          data-toggle="tab"
          href="#ppdb-justify"
          role="tab"
          aria-controls="ppdb"
          aria-selected="false"
        >
          Kelola Informasi PPDB
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          id="prestasi-tab-justify"
          data-toggle="tab"
          href="#prestasi-justify"
          role="tab"
          aria-controls="prestasi"
          aria-selected="false"
        >
          Kelola Papan Prestasi
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          id="profile-tab-justify"
          data-toggle="tab"
          href="#profile-justify"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          Kelola Data Sekolah
        </a>
      </li>

      <li className="nav-item">
        <a
          className="nav-link"
          id="pegawai-tab-justify"
          data-toggle="tab"
          href="#pegawai-justify"
          role="tab"
          aria-controls="pegawai"
          aria-selected="false"
        >
          Kelola Data Pegawai
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          id="akses-tab-justify"
          data-toggle="tab"
          href="#akses-justify"
          role="tab"
          aria-controls="akses"
          aria-selected="false"
        >
          Kelola Akses Pegawai
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          id="jadwal-tab-justify"
          data-toggle="tab"
          href="#jadwal-justify"
          role="tab"
          aria-controls="jadwal"
          aria-selected="false"
        >
          Kelola Jadwal Pelajaran
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          id="piket-tab-justify"
          data-toggle="tab"
          href="#piket-justify"
          role="tab"
          aria-controls="piket"
          aria-selected="false"
        >
          Kelola Petugas Piket
        </a>
      </li>
    </ul>
  );
}

export default Menu;
