import React, { useState, useEffect } from "react";
import axios from "axios";

function Piket() {
  const [petugas, setPetugas] = useState(null);
  const [error, setError] = useState(null);

  // Mendapatkan hari ini dalam format yang sama dengan yang digunakan di tabel
  const hariIni = new Date().toLocaleDateString("id-ID", { weekday: "long" });

  useEffect(() => {
    // Mengambil data petugas piket dari API
    const fetchPetugas = async () => {
      try {
        // Ambil semua data petugas dari API
        const response = await axios.get(`/petugas-piket`);
        const dataPetugas = response.data;

        // Filter data petugas berdasarkan hari ini
        const petugasHariIni = dataPetugas.find(
          (petugas) => petugas.hari === hariIni
        );

        if (petugasHariIni) {
          setPetugas(petugasHariIni);
        } else {
          setPetugas({
            hari: hariIni,
            kode_guru: null,
            kontak: null,
            tenaga_pendidik: { nama: "Tidak ada petugas" }
          });
        }
      } catch (err) {
        setError("Gagal memuat data petugas.");
      }
    };

    fetchPetugas();
  }, [hariIni]);

  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card card-block card-stretch card-height">
        <div className="card-body text-center">
          <h5 className="mb-3 text-primary font-weight-bold">Petugas Piket</h5>
          <svg
            width={60}
            height={48}
            fill="blue"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path d="M528 160l0 256c0 8.8-7.2 16-16 16l-192 0c0-44.2-35.8-80-80-80l-64 0c-44.2 0-80 35.8-80 80l-32 0c-8.8 0-16-7.2-16-16l0-256 480 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM272 256a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zm104-48c-13.3 0-24 10.7-24 24s10.7 24 24 24l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0z" />
          </svg>

          <h6 className="mb-2 mt-4">
            {error ? (
              <span className="text-danger">{error}</span>
            ) : petugas ? (
              petugas.tenaga_pendidik ? (
                <>
                  {petugas.tenaga_pendidik.nama}
                  <br />
                  {petugas.kontak && (
                    <u style={{ color: "green" }}>
                      <a href={`https://wa.me/${petugas.kontak}`}>
                        <i className="fa-brands fa-whatsapp" /> Hubungi
                      </a>
                    </u>
                  )}
                </>
              ) : (
                <span>Tidak ada petugas</span>
              )
            ) : (
              <span className="loader"></span>
            )}
          </h6>
        </div>
      </div>
    </div>
  );
}

export default Piket;
