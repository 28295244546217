import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

function FileInFolder() {
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      fetchFilesInFolder(id);
    }
  }, [id]);

  // Fungsi untuk menentukan ikon berdasarkan jenis file
  const getIconForFile = (item) => {
    if (item.type === "folder") {
      return "../assets/images/folder-1.png";
    }

    const extension = item.name.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "../../assets/images/pdf.png";
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return "../../assets/images/img.png";
      case "doc":
      case "docx":
        return "../../assets/images/doc.png";
      case "ppt":
      case "pptx":
        return "../../assets/images/ppt.png";
      case "xls":
      case "xlsx":
        return "../../assets/images/xlsx.png";
      default:
        return "../../assets/images/folder-1.png";
    }
  };

  // Fungsi untuk format ukuran file
  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) return sizeInBytes + " B";
    let size = sizeInBytes / 1024;
    if (size < 1024) return size.toFixed(2) + " KB";
    size = size / 1024;
    if (size < 1024) return size.toFixed(2) + " MB";
    size = size / 1024;
    return size.toFixed(2) + " GB";
  };

  // Fungsi untuk format tanggal
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  // Fungsi untuk memuat file dalam folder dari backend
  const fetchFilesInFolder = (id) => {
    setIsLoading(true);

    axios
      .get(`/cloud-storage/folder/${id}`)
      .then((response) => {
        // Set nama folder dan daftar file dari response
        setFolderName(response.data.folder_name); // Set nama folder ke state
        setFiles(response.data.files); // Set file ke state
      })
      .catch(() => {
        Swal.fire({
          title: "Gagal!",
          text: "Tidak bisa memuat file di folder ini.",
          icon: "error",
          confirmButtonText: "OK"
        });
        setFolderName("Folder Tidak Ditemukan"); // Fallback jika gagal
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Fungsi untuk mengunggah file dalam folder
  const handleUpload = async (e) => {
    e.preventDefault();

    const { value: formValues } = await Swal.fire({
      title: "Unggah File ke Folder",
      html:
        '<input type="file" id="swal-file-input" class="swal2-input mb-3" style="padding: 3px;" accept="*">' +
        '<div style="display: flex; justify-content: space-between;">' +
        '<input id="swal-input-file-name" class="swal2-input" placeholder="Nama File" style="width: 70%;">' +
        '<input id="swal-input-file-extension" class="swal2-input bg-secondary" placeholder="" style="width: 25%;" readonly>' +
        "</div>" +
        '<textarea id="swal-input2" class="swal2-textarea" placeholder="Keterangan File"></textarea>',
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Unggah",
      cancelButtonText: "Batal",
      preConfirm: () => {
        const selectedFile =
          document.getElementById("swal-file-input").files[0];
        const fileName = document.getElementById("swal-input-file-name").value;
        const fileExtension = document.getElementById(
          "swal-input-file-extension"
        ).value;
        const fileKeterangan = document.getElementById("swal-input2").value;

        if (!selectedFile) {
          Swal.showValidationMessage("Anda harus memilih file!");
          return false;
        }
        if (!fileName) {
          Swal.showValidationMessage("Nama file tidak boleh kosong!");
          return false;
        }

        return {
          selectedFile,
          fileName: `${fileName}.${fileExtension}`,
          fileKeterangan
        };
      },
      didOpen: () => {
        const fileInput = document.getElementById("swal-file-input");
        fileInput.addEventListener("change", function () {
          const selectedFile = fileInput.files[0];
          if (selectedFile) {
            const fullFileName = selectedFile.name;
            const fileParts = fullFileName.split(".");
            const extension = fileParts.pop();
            const nameWithoutExtension = fileParts.join(".");

            document.getElementById("swal-input-file-name").value =
              nameWithoutExtension;
            document.getElementById("swal-input-file-extension").value =
              extension;
          }
        });
      }
    });

    if (formValues) {
      const { selectedFile, fileName, fileKeterangan } = formValues;
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("name", fileName); // Kirim nama file kustom ke backend
      formData.append("keterangan", fileKeterangan);
      formData.append("folder_id", id); // Tambahkan folder_id

      axios
        .post(`/cloud-storage/upload-file`, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            Swal.fire({
              title: "Mengunggah...",
              text: `${percentCompleted}% terunggah`,
              allowOutsideClick: false,
              showConfirmButton: false,
              willOpen: () => {
                Swal.showLoading();
              }
            });
          }
        })
        .then(() => {
          Swal.fire({
            title: "Berhasil!",
            text: "File berhasil diunggah ke folder.",
            icon: "success",
            confirmButtonText: "OK"
          });
          fetchFilesInFolder(id);
        })
        .catch((error) => {
          Swal.fire({
            title: "Gagal!",
            text: error.response?.data?.message || "Gagal mengunggah file.",
            icon: "error",
            confirmButtonText: "OK"
          });
        });
    }
  };

  // Fungsi untuk menghapus file
  const handleDelete = (fileId) => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Anda tidak akan bisa mengembalikan file yang dihapus!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal"
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/cloud-storage/delete/${fileId}`)
          .then(() => {
            Swal.fire("Dihapus!", "File berhasil dihapus.", "success");
            fetchFilesInFolder(id);
          })
          .catch(() => {
            Swal.fire("Gagal!", "Tidak bisa menghapus file.", "error");
          });
      }
    });
  };

  // Fungsi untuk melihat file
  const handleView = (file) => {
    const extension = file.name.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const docExtensions = ["pdf", "doc", "docx", "ppt", "pptx", "xls", "xlsx"];

    // Cek URL Laravel untuk mengakses file yang berada di storage/public
    const fileUrl = `https://smamutas.sch.id/storage/${file.path}`;

    if (imageExtensions.includes(extension)) {
      // Jika file adalah gambar, tampilkan gambar di modal
      Swal.fire({
        title: file.name,
        imageUrl: fileUrl, // URL dari route Laravel
        imageAlt: file.name,
        showCancelButton: true,
        confirmButtonText: "Download",
        cancelButtonText: "Tutup",
        didOpen: () => {
          // Cek jika gambar tidak bisa dimuat
          const img = Swal.getImage();
          img.onerror = () => {
            Swal.showValidationMessage("Gambar tidak bisa dimuat.");
          };
        },
        preConfirm: () => {
          // Download file saat tombol "Download" diklik
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    } else if (docExtensions.includes(extension)) {
      // Jika file adalah dokumen, tampilkan dokumen di iframe
      Swal.fire({
        title: file.name,
        html: `<iframe src="${fileUrl}" width="100%" height="400px"></iframe>`,
        showCancelButton: true,
        confirmButtonText: "Download",
        cancelButtonText: "Tutup",
        preConfirm: () => {
          // Download file saat tombol "Download" diklik
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    } else {
      // Jika file bukan gambar atau dokumen, langsung unduh file
      Swal.fire({
        title: "File ini tidak bisa dilihat secara langsung",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Download",
        cancelButtonText: "Tutup",
        preConfirm: () => {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    }
  };

  return (
    <div className="content-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-block card-stretch card-transparent">
              <div className="card-header d-flex justify-content-between pb-0">
                <div className="header-title">
                  <h5 className="card-title">Folder {folderName}</h5>
                  <Link to="/cloud">
                    <button className="btn btn-outline-primary btn-sm mt-2">
                      {" "}
                      <i className="fa-solid fa-arrow-left-long" /> Kembali
                    </button>
                  </Link>
                </div>
                <div className="card-header-toolbar">
                  <button className="btn btn-primary" onClick={handleUpload}>
                    Tambah File ke Folder
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card card-block card-stretch card-height">
              <div className="card-body">
                {isLoading ? (
                  <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Memuat...</span>
                    </div>
                  </div>
                ) : files.length === 0 ? (
                  <div className="card card-body text-center">
                    <p>Tidak Ada File di Folder Ini.</p>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table mb-0 table-borderless tbl-server-info">
                      <thead>
                        <tr>
                          <th scope="col">Nama File</th>
                          <th scope="col">Keterangan</th>
                          <th scope="col">Tanggal Upload</th>
                          <th scope="col">Ukuran File</th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {files.map((file) => (
                          <tr key={file.id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="rounded mr-3">
                                  <img
                                    loading="lazy"
                                    src={getIconForFile(file)}
                                    alt="ikon-file"
                                    width="50"
                                  />
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleView(file)}
                                  >
                                    {file.name}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>{file.keterangan || "Tanpa Keterangan"}</td>
                            <td>{formatDate(file.created_at)}</td>
                            <td>{formatFileSize(file.size)}</td>
                            <td>
                              <button
                                className="btn btn-primary mb-2"
                                onClick={() => handleView(file)}
                              >
                                Lihat
                              </button>{" "}
                              <button
                                className="btn btn-danger mb-2"
                                onClick={() => handleDelete(file.id)}
                              >
                                Hapus
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileInFolder;
