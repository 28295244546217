import React from "react";

function Home() {
  return (
    <div
      className="tab-pane fade show active"
      id="home-justify"
      role="tabpanel"
      aria-labelledby="home-tab-justify"
    >
      <div className="alert alert-primary mt-0" role="alert">
        <div className="iq-alert-text">
          Pusat Sistem <b>SMA Muhammadiyah Tasikmalaya</b>
          <br />
          <small className="text-danger font-weight-bold">
            *Silahkan memilih salah satu menu diatas.
          </small>
        </div>
      </div>
    </div>
  );
}

export default Home;
