import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

// Fungsi untuk mengubah ukuran file ke dalam KB/MB/GB
const formatSize = (size) => {
  if (size < 1024) return `${size} B`;
  else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
  else if (size < 1024 * 1024 * 1024)
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
};

// Fungsi untuk format tanggal ke bahasa Indonesia
const formatTanggal = (tanggal) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(tanggal).toLocaleDateString("id-ID", options);
};

// Fungsi untuk menentukan ikon berdasarkan jenis file
const getIconForFile = (item) => {
  if (item.type === "folder") {
    return "../assets/images/folder-1.png";
  }

  const extension = item.name.split(".").pop().toLowerCase();

  switch (extension) {
    case "pdf":
      return "../assets/images/pdf.png";
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return "../assets/images/img.png";
    case "doc":
    case "docx":
      return "../assets/images/doc.png";
    case "ppt":
    case "pptx":
      return "../assets/images/ppt.png";
    case "xls":
    case "xlsx":
      return "../assets/images/xlsx.png";
    default:
      return "../assets/images/file.png"; // Default icon for other file types
  }
};

function TerakhirDitambahkan() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data terakhir ditambahkan dari API
    axios
      .get("/cloud-storage/recent")
      .then((response) => {
        // Mengambil hanya 6 data terbaru
        const recentData = response.data.slice(0, 6);
        setData(recentData);
        setLoading(false);
      })
      .catch((error) => {
        setError("Gagal mengambil data.");
        setLoading(false);
      });
  }, []);

  // Fungsi untuk membuka folder
  const handleOpenFolder = (id) => {
    navigate(`/cloud/folder/${id}`);
  };

  const handleItemClick = (item) => {
    if (item.type === "folder") {
      handleOpenFolder(item.id); // Jika folder, buka folder
    } else if (item.type === "file") {
      handleView(item); // Jika file, panggil handleView
    }
  };
  
  // Fungsi untuk melihat file
  const handleView = (file) => {
    const extension = file.name.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const docExtensions = [
      "pdf", "doc", "docx", "ppt", "pptx", "xls", "xlsx"
    ];
  
    // Cek URL Laravel untuk mengakses file yang berada di storage/public
    const fileUrl = `https://smamutas.sch.id/storage/${file.path}`;
  
    if (imageExtensions.includes(extension)) {
      // Jika file adalah gambar, tampilkan gambar di modal
      Swal.fire({
        title: file.name,
        imageUrl: fileUrl, // URL dari route Laravel
        imageAlt: file.name,
        showCancelButton: true,
        confirmButtonText: "Download",
        cancelButtonText: "Tutup",
        didOpen: () => {
          // Cek jika gambar tidak bisa dimuat
          const img = Swal.getImage();
          img.onerror = () => {
            Swal.showValidationMessage("Gambar tidak bisa dimuat.");
          };
        },
        preConfirm: () => {
          // Download file saat tombol "Download" diklik
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    } else if (docExtensions.includes(extension)) {
      // Jika file adalah dokumen, tampilkan dokumen di iframe
      Swal.fire({
        title: file.name,
        html: `<iframe src="${fileUrl}" width="100%" height="400px"></iframe>`,
        showCancelButton: true,
        confirmButtonText: "Download",
        cancelButtonText: "Tutup",
        preConfirm: () => {
          // Download file saat tombol "Download" diklik
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    } else {
      // Jika file bukan gambar atau dokumen, langsung unduh file
      Swal.fire({
        title: "File ini tidak bisa dilihat secara langsung",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Download",
        cancelButtonText: "Tutup",
        preConfirm: () => {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    }
  };
  

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card card-block card-stretch card-transparent">
          <div className="card-header d-flex justify-content-between pb-0">
            <div className="header-title">
              <h4 className="card-title">Terakhir Ditambahkan</h4>
            </div>
          </div>

          <div className="card-body">
            {loading && (
              <div className="card card-body text-center">
                <p>Sedang memuat data...</p>
              </div>
            )}

            {error && (
              <div className="card card-body text-center text-danger">
                <p>{error}</p>
              </div>
            )}

            {!loading && !error && data.length === 0 && (
              <div className="card card-body text-center">
                <p>Tidak Ada File/Folder yang baru ditambahkan.</p>
              </div>
            )}

            {!loading && !error && data.length > 0 && (
              <div className="row">
                {data.map((item) => (
                  <div
                    className="col-lg-2 col-md-6 col-sm-6"
                    key={item.id}
                    style={{ cursor: "pointer" }} // Mengubah bentuk cursor
                  >
                    <div
                      className="card card-block card-stretch card-height"
                      onClick={() => handleItemClick(item)}
                    >
                      <div className="card-body image-thumb">
                        <div className="mb-4 text-center p-3 rounded iq-thumb">
                          <img
                            src={getIconForFile(item)}
                            className="img-fluid"
                            alt={item.name}
                          />
                        </div>
                        <h6>
                          {item.type === "folder"
                            ? "Nama Folder: "
                            : "Nama File: "}
                          <br />
                          {item.name}
                          <p className="text-primary font-weight-bold">
                            <i className="fa-solid fa-clock" />{" "}
                            {formatTanggal(item.created_at)} <br />
                            {item.type === "file" && (
                              <>Ukuran: {formatSize(item.size)}</>
                            )}
                          </p>
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TerakhirDitambahkan;
