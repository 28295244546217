import React, { useState, useEffect } from "react";
import axios from "axios";

function ProfilePegawai() {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // State untuk loading

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get("/pegawai", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setProfile(response.data);
      } catch (err) {
        setError("Gagal memuat profil.");
      } finally {
        setLoading(false); // Set loading ke false setelah data diterima
      }
    };

    fetchProfile();
  }, []);

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card card-block card-stretch card-height">
        <div className="card-body text-center">
          <h5 className="mb-3 text-primary font-weight-bold">Profile</h5>

          {loading ? (
            // Tampilkan spinner saat sedang memuat data
            <span class="loader"></span>
          ) : (
            profile && (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={60}
                  height={48}
                  fill="red"
                  viewBox="0 0 448 512"
                >
                  <path d="M96 128a128 128 0 1 0 256 0A128 128 0 1 0 96 128zm94.5 200.2l18.6 31L175.8 483.1l-36-146.9c-2-8.1-9.8-13.4-17.9-11.3C51.9 342.4 0 405.8 0 481.3c0 17 13.8 30.7 30.7 30.7l131.7 0c0 0 0 0 .1 0l5.5 0 112 0 5.5 0c0 0 0 0 .1 0l131.7 0c17 0 30.7-13.8 30.7-30.7c0-75.5-51.9-138.9-121.9-156.4c-8.1-2-15.9 3.3-17.9 11.3l-36 146.9L238.9 359.2l18.6-31c6.4-10.7-1.3-24.2-13.7-24.2L224 304l-19.7 0c-12.4 0-20.1 13.6-13.7 24.2z" />
                </svg>

                <h6 className="mb-2 mt-4">
                  {profile.tenaga_pendidik ? (
                    <>
                      {profile.tenaga_pendidik.nama}
                      <p>
                        {profile.tenaga_pendidik.jabatan}{" "}
                        {profile.tenaga_pendidik.mata_pelajaran}
                      </p>
                    </>
                  ) : profile.tenaga_kependidikan ? (
                    <>
                      {profile.tenaga_kependidikan.nama}
                      <p>{profile.tenaga_kependidikan.jabatan}</p>
                    </>
                  ) : (
                    <p>Data jabatan tidak ditemukan.</p>
                  )}
                </h6>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfilePegawai;
