import React from "react";

import Sidebar from "../Layouts/Components/Sidebar";
import Topbar from "../Layouts/Components/Topbar";
import Footer from "../Layouts/Components/Footer";

const MainLayouts = ({ children }) => {
  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <Topbar />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default MainLayouts;
