import React from "react";
import Banner from "./Components/Banner";
import Piket from "./Components/Piket";
import ProfilePegawai from "./Components/ProfilePegawai";
import JadwalGuru from "./Components/JadwalGuru";
import TMT from "./Components/Tmt";
import JadwalFull from "./Components/JadwalFull";
import ProtectedRoute from "../Authentication/ProtectedRoute";

function Content() {
  return (
    <ProtectedRoute>
      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <Banner />
            <Piket />
            <JadwalGuru />
            <ProfilePegawai />
            <TMT />
            <JadwalFull />
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
}

export default Content;
