import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function WebsiteProfile() {
  const [profileSekolah, setProfileSekolah] = useState({});
  const [rekapitulasi, setRekapitulasi] = useState({});
  const [saranaPrasarana, setSaranaPrasarana] = useState({});
  const [kontak, setKontak] = useState({});
  const [kodePegawai, setKodePegawai] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/pegawai")
      .then((response) => {
        const kode_pegawai = response.data.kode_pegawai;
        setKodePegawai(kode_pegawai);

        if (
          kode_pegawai === "SMAM-OPERATOR" ||
          kode_pegawai === "SMAM-KEPALA"
        ) {
          fetchData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Akses Ditolak",
            text: "Anda tidak memiliki izin untuk mengakses halaman ini."
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Kesalahan",
          text: "Gagal mengambil data pengguna!"
        });
        setIsLoading(false);
      });
  }, []);

  // Fungsi untuk mengambil data dari API
  const fetchData = async () => {
    try {
      const profileResponse = await axios.get("/administrator/profile-sekolah");
      setProfileSekolah(profileResponse.data[0] || {});

      const rekapResponse = await axios.get("/administrator/rekapitulasi");
      setRekapitulasi(rekapResponse.data[0] || {});

      const saranaResponse = await axios.get("/administrator/saranaprasarana");
      setSaranaPrasarana(saranaResponse.data[0] || {});

      const kontakResponse = await axios.get("/administrator/kontak");
      setKontak(kontakResponse.data[0] || {});
    } catch (error) {
      console.error("Kesalahan mengambil data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Fungsi untuk menambahkan atau mengedit data
  const addOrEditData = async (section) => {
    let sectionData = {};
    let endpoint = "";
    let formHtml = "";
    let formValues = [];

    // Tentukan bagian (section) yang akan diedit
    switch (section) {
      case "profile-sekolah":
        sectionData = profileSekolah;
        endpoint = `/administrator/profile-sekolah/${sectionData.id}`; // Gunakan endpoint dengan ID untuk edit data
        formHtml =
          `<label for="swal-input1">Kepala Sekolah</label>` +
          `<input id="swal-input1" class="swal2-input" placeholder="Masukkan Kepala Sekolah" value="${
            sectionData.kepala_sekolah || ""
          }">` +
          `<label for="swal-input2">Operator</label>` +
          `<input id="swal-input2" class="swal2-input" placeholder="Masukkan Operator" value="${
            sectionData.operator || ""
          }">` +
          `<label for="swal-input3">Akreditasi</label>` +
          `<input id="swal-input3" class="swal2-input" placeholder="Masukkan Akreditasi" value="${
            sectionData.akreditasi || ""
          }">` +
          `<label for="swal-input4">Kurikulum</label>` +
          `<input id="swal-input4" class="swal2-input" placeholder="Masukkan Kurikulum" value="${
            sectionData.kurikulum || ""
          }">` +
          `<label for="swal-input5">Waktu Penyelenggaraan</label>` +
          `<input id="swal-input5" class="swal2-input" placeholder="Masukkan Waktu Penyelenggaraan" value="${
            sectionData.waktu_penyelenggaraan || ""
          }">` +
          `<label for="swal-input6">NPSN</label>` +
          `<input id="swal-input6" class="swal2-input" placeholder="Masukkan NPSN" value="${
            sectionData.npsn || ""
          }">` +
          `<label for="swal-input7">Status</label>` +
          `<input id="swal-input7" class="swal2-input" placeholder="Masukkan Status" value="${
            sectionData.status || ""
          }">` +
          `<label for="swal-input8">Bentuk Pendidikan</label>` +
          `<input id="swal-input8" class="swal2-input" placeholder="Masukkan Bentuk Pendidikan" value="${
            sectionData.bentuk_pendidikan || ""
          }">` +
          `<label for="swal-input9">Status Kepemilikan</label>` +
          `<input id="swal-input9" class="swal2-input" placeholder="Masukkan Status Kepemilikan" value="${
            sectionData.status_kepemilikan || ""
          }">`;
        break;

      case "rekapitulasi":
        sectionData = rekapitulasi;
        endpoint = `/administrator/rekapitulasi/${sectionData.id}`;
        formHtml =
          `<label for="swal-input1">Guru Laki-laki</label>` +
          `<input id="swal-input1" class="swal2-input" placeholder="Masukkan Guru Laki-laki" value="${
            sectionData.guru_lakilaki || ""
          }">` +
          `<label for="swal-input2">Guru Perempuan</label>` +
          `<input id="swal-input2" class="swal2-input" placeholder="Masukkan Guru Perempuan" value="${
            sectionData.guru_perempuan || ""
          }">` +
          `<label for="swal-input3">Tendik Laki-laki</label>` +
          `<input id="swal-input3" class="swal2-input" placeholder="Masukkan Tendik Laki-laki" value="${
            sectionData.tendik_lakilaki || ""
          }">` +
          `<label for="swal-input4">Tendik Perempuan</label>` +
          `<input id="swal-input4" class="swal2-input" placeholder="Masukkan Tendik Perempuan" value="${
            sectionData.tendik_perempuan || ""
          }">` +
          `<label for="swal-input5">Total PTK</label>` +
          `<input id="swal-input5" class="swal2-input" placeholder="Masukkan Total PTK" value="${
            sectionData.total_ptk || ""
          }">` +
          `<label for="swal-input6">Peserta Didik Laki-laki</label>` +
          `<input id="swal-input6" class="swal2-input" placeholder="Masukkan Peserta Didik Laki-laki" value="${
            sectionData.pesertadidik_lakilaki || ""
          }">` +
          `<label for="swal-input7">Peserta Didik Perempuan</label>` +
          `<input id="swal-input7" class="swal2-input" placeholder="Masukkan Peserta Didik Perempuan" value="${
            sectionData.pesertadidik_perempuan || ""
          }">` +
          `<label for="swal-input8">Total Peserta Didik</label>` +
          `<input id="swal-input8" class="swal2-input" placeholder="Masukkan Total Peserta Didik" value="${
            sectionData.total_pesertadidik || ""
          }">`;
        break;

      case "saranaprasarana":
        sectionData = saranaPrasarana;
        endpoint = `/administrator/saranaprasarana/${sectionData.id}`;
        formHtml =
          `<label for="swal-input1">Ruang Kelas</label>` +
          `<input id="swal-input1" class="swal2-input" placeholder="Masukkan Ruang Kelas" value="${
            sectionData.ruang_kelas || ""
          }">` +
          `<label for="swal-input2">Ruang Perpustakaan</label>` +
          `<input id="swal-input2" class="swal2-input" placeholder="Masukkan Ruang Perpustakaan" value="${
            sectionData.ruang_perpustakaan || ""
          }">` +
          `<label for="swal-input3">Ruang Laboratorium</label>` +
          `<input id="swal-input3" class="swal2-input" placeholder="Masukkan Ruang Laboratorium" value="${
            sectionData.ruang_laboratorium || ""
          }">` +
          `<label for="swal-input4">Ruang Praktik</label>` +
          `<input id="swal-input4" class="swal2-input" placeholder="Masukkan Ruang Praktik" value="${
            sectionData.ruang_praktik || ""
          }">` +
          `<label for="swal-input5">Ruang Pimpinan</label>` +
          `<input id="swal-input5" class="swal2-input" placeholder="Masukkan Ruang Pimpinan" value="${
            sectionData.ruang_pimpinan || ""
          }">`;
        break;

      case "kontak":
        sectionData = kontak;
        endpoint = `/administrator/kontak/${sectionData.id}`;
        formHtml =
          `<label for="swal-input1">Alamat</label>` +
          `<input id="swal-input1" class="swal2-input" placeholder="Masukkan Alamat" value="${
            sectionData.alamat || ""
          }">` +
          `<label for="swal-input2">RT</label>` +
          `<input id="swal-input2" class="swal2-input" placeholder="Masukkan RT" value="${
            sectionData.rt || ""
          }">` +
          `<label for="swal-input3">RW</label>` +
          `<input id="swal-input3" class="swal2-input" placeholder="Masukkan RW" value="${
            sectionData.rw || ""
          }">` +
          `<label for="swal-input4">Kelurahan</label>` +
          `<input id="swal-input4" class="swal2-input" placeholder="Masukkan Kelurahan" value="${
            sectionData.kelurahan || ""
          }">` +
          `<label for="swal-input5">Kecamatan</label>` +
          `<input id="swal-input5" class="swal2-input" placeholder="Masukkan Kecamatan" value="${
            sectionData.kecamatan || ""
          }">` +
          `<label for="swal-input6">Kota/Kabupaten</label>` +
          `<input id="swal-input6" class="swal2-input" placeholder="Masukkan Kota/Kabupaten" value="${
            sectionData.kota || ""
          }">` +
          `<label for="swal-input7">Provinsi</label>` +
          `<input id="swal-input7" class="swal2-input" placeholder="Masukkan Provinsi" value="${
            sectionData.provinsi || ""
          }">` +
          `<label for="swal-input8">Kode Pos</label>` +
          `<input id="swal-input8" class="swal2-input" placeholder="Masukkan Kode Pos" value="${
            sectionData.kode_pos || ""
          }">`;
        break;

      default:
        Swal.fire({
          icon: "error",
          title: "Kesalahan",
          text: "Bagian tidak ditemukan!"
        });
        return;
    }

    Swal.fire({
      title: "Edit Data",
      html: formHtml,
      focusConfirm: false,
      preConfirm: () => {
        // Ambil input dari swal input
        formValues = [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
          document.getElementById("swal-input3").value,
          document.getElementById("swal-input4").value,
          document.getElementById("swal-input5")?.value || "",
          document.getElementById("swal-input6")?.value || "",
          document.getElementById("swal-input7")?.value || "",
          document.getElementById("swal-input8")?.value || "",
          document.getElementById("swal-input9")?.value || ""
        ];

        // Tentukan perubahan data berdasarkan section
        switch (section) {
          case "profile-sekolah":
            sectionData = {
              ...sectionData,
              kepala_sekolah: formValues[0],
              operator: formValues[1],
              akreditasi: formValues[2],
              kurikulum: formValues[3],
              waktu_penyelenggaraan: formValues[4],
              npsn: formValues[5],
              status: formValues[6],
              bentuk_pendidikan: formValues[7],
              status_kepemilikan: formValues[8]
            };
            break;

          case "rekapitulasi":
            sectionData = {
              ...sectionData,
              guru_lakilaki: formValues[0],
              guru_perempuan: formValues[1],
              tendik_lakilaki: formValues[2],
              tendik_perempuan: formValues[3],
              total_ptk: formValues[4],
              pesertadidik_lakilaki: formValues[5],
              pesertadidik_perempuan: formValues[6],
              total_pesertadidik: formValues[7]
            };
            break;

          case "saranaprasarana":
            sectionData = {
              ...sectionData,
              ruang_kelas: formValues[0],
              ruang_perpustakaan: formValues[1],
              ruang_laboratorium: formValues[2],
              ruang_praktik: formValues[3],
              ruang_pimpinan: formValues[4]
            };
            break;

          case "kontak":
            sectionData = {
              ...sectionData,
              alamat: formValues[0],
              rt: formValues[1],
              rw: formValues[2],
              kelurahan: formValues[3],
              kecamatan: formValues[4],
              kota: formValues[5],
              provinsi: formValues[6],
              kode_post: formValues[7]
            };
            break;

          default:
            Swal.fire({
              icon: "error",
              title: "Kesalahan",
              text: "Bagian tidak ditemukan!"
            });
            return;
        }

        // Lakukan permintaan untuk update data ke API
        axios
          .put(endpoint, sectionData)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Data berhasil diperbarui!"
            });
            fetchData(); // Ambil ulang data setelah edit berhasil
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "Kesalahan",
              text: "Gagal memperbarui data!"
            });
          });
      }
    });
  };

  // LOADING
  if (isLoading) {
    return <div className="spinner"></div>;
  }

  return (
    <div
      className="tab-pane fade"
      id="profile-justify"
      role="tabpanel"
      aria-labelledby="profile-tab-justify"
    >
      {/* Tombol untuk masing-masing bagian */}
      <button
        className="btn btn-primary mb-2"
        onClick={() => addOrEditData("profile-sekolah")}
      >
        Edit Profile Sekolah
      </button>{" "}
      <button
        className="btn btn-primary mb-2"
        onClick={() => addOrEditData("rekapitulasi")}
      >
        Edit Rekapitulasi
      </button>{" "}
      <button
        className="btn btn-primary mb-2"
        onClick={() => addOrEditData("saranaprasarana")}
      >
        Edit Sarana Prasarana
      </button>{" "}
      <button
        className="btn btn-primary mb-2"
        onClick={() => addOrEditData("kontak")}
      >
        Edit Kontak
      </button>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="iq-alert-text">
          Daftar Data Pokok Sekolah <b>SMA Muhammadiyah Tasikmalaya</b>
        </div>
      </div>
      {/* DATA */}
      <div className="col-sm-12 mt-3">
        <div className="card">
          <div className="row">
            {/* PROFILE SEKOLAH */}
            <div class="col-lg-3 col-md-6">
              <div class="card card-block card-stretch card-height">
                <div class="card-header">
                  <div class="header-title">
                    <h4 class="card-title text-primary font-weight-bold">
                      Profile Sekolah
                    </h4>
                  </div>
                </div>
                <hr />
                <div class="card-body">
                  <ul class="list-inline p-0 m-0">
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Kepala Sekolah :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {profileSekolah.kepala_sekolah ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Operator :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {profileSekolah.operator || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Akreditasi :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {profileSekolah.akreditasi || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Kurikulum :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {profileSekolah.kurikulum || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Penyelenggaraan :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {profileSekolah.waktu_penyelenggaraan ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">NPSN : </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {profileSekolah.npsn || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">Status : </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {profileSekolah.status || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Bentuk Pendidikan :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {profileSekolah.bentuk_pendidikan ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Kepemilikan :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {profileSekolah.status_kepemilikan ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* REKAPITULASI */}
            <div class="col-lg-3 col-md-6">
              <div class="card card-block card-stretch card-height">
                <div class="card-header">
                  <div class="header-title">
                    <h4 class="card-title text-primary font-weight-bold">
                      Rekapitulasi
                    </h4>
                  </div>
                </div>
                <hr />
                <div class="card-body">
                  <ul class="list-inline p-0 m-0">
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Guru Laki - Laki :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {rekapitulasi.guru_lakilaki ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Guru Perempuan :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {rekapitulasi.guru_perempuan ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Tendik Laki - Laki :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {rekapitulasi.tendik_lakilaki ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Tendik Perempuan :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {rekapitulasi.tendik_perempuan ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Total PTK :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {rekapitulasi.total_ptk || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Peserta Didik Laki - Laki :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {rekapitulasi.pesertadidik_lakilaki ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Peserta Didik Perempuan :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {rekapitulasi.pesertadidik_perempuan ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Total Peserta Didik :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {rekapitulasi.total_pesertadidik ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* SARANA DAN PRASARANA */}
            <div class="col-lg-3 col-md-6">
              <div class="card card-block card-stretch card-height">
                <div class="card-header">
                  <div class="header-title">
                    <h4 class="card-title text-primary font-weight-bold">
                      Sarana Prasarana
                    </h4>
                  </div>
                </div>
                <hr />
                <div class="card-body">
                  <ul class="list-inline p-0 m-0">
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Ruang Kelas :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {saranaPrasarana.ruang_kelas ||
                              "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Ruang Perpustakaan :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {saranaPrasarana.ruang_perpustakaan || "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Ruang Labolatorium :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {saranaPrasarana.ruang_laboratorium || "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Ruang Praktik :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {saranaPrasarana.ruang_praktik || "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Ruang Pimpinan :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {saranaPrasarana.ruang_pimpinan || "-"}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* KONTAK */}
            <div class="col-lg-3 col-md-6">
              <div class="card card-block card-stretch card-height">
                <div class="card-header">
                  <div class="header-title">
                    <h4 class="card-title text-primary font-weight-bold">
                      Kontak
                    </h4>
                  </div>
                </div>
                <hr />
                <div class="card-body">
                  <ul class="list-inline p-0 m-0">
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">Alamat : </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {kontak.alamat || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">RT/RW : </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {kontak.rt || "Data tidak tersedia"}/
                            {kontak.rw || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Kelurahan :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {kontak.kelurahan || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Kecamatan :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {kontak.kecamatan || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Kota/Kabupaten :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {kontak.kota || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Provinsi :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {kontak.provinsi || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="badge badge-light iq-number">
                          Kode Pos :{" "}
                        </div>
                        <div class="ml-0">
                          <p class="mb-0 font-size-16">
                            {kontak.kode_pos || "Data tidak tersedia"}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebsiteProfile;
