import React from "react";

function SearchbarContent() {
  return (
    <li className="nav-item nav-icon search-content">
      <a
        href="#"
        className="search-toggle rounded"
        id="dropdownSearch"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="ri-search-line" />
      </a>
      <div
        className="iq-search-bar iq-sub-dropdown dropdown-menu"
        aria-labelledby="dropdownSearch"
      >
        <form action="#" className="searchbox p-2">
          <div className="form-group mb-0 position-relative">
            <input
              type="text"
              className="text search-input font-size-12"
              placeholder="ketik di sini untuk mencari..."
            />
            <a href="#" className="search-link">
              <i className="las la-search" />
            </a>
          </div>
        </form>
      </div>
    </li>
  );
}

export default SearchbarContent;
