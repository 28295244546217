import React from "react";

function Setting() {
  return (
    <li className="nav-item nav-icon dropdown">
      <a
        href="#"
        className="search-toggle dropdown-toggle"
        id="dropdownMenuButton02"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="ri-settings-3-line" />
      </a>
      <div
        className="iq-sub-dropdown dropdown-menu"
        aria-labelledby="dropdownMenuButton02"
      >
        <div className="card shadow-none m-0">
          <div className="card-body p-0">
            <div className="p-3">
              <a href="#" className="iq-sub-card pt-0">
                <i className="ri-settings-3-line" /> Pengaturan
              </a>
              <a href="#" className="iq-sub-card">
                <i className="ri-hard-drive-line" /> Dapatkan Drive untuk
                desktop
              </a>
              <a href="#" className="iq-sub-card">
                <i className="ri-keyboard-line" /> Pintasan Keyboard
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default Setting;
